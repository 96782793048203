import React, { useState, useEffect, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import "toastr/build/toastr.min.css";
import DateUtils from "../../../../../services/utils/DateUtils";
import axios from "axios";
import {
    API_URL_GET_REPORT_LIST
} from '../../../constants';
import { GDPR_REPORT_STATUS_LIST } from "src/modules/data-protection/constants/Common";
import {
  INTERNATIONAL_DATE_FORMAT,
} from "../../../../../common/constants";
import SecurityBreachImage from "src/modules/data-protection/assets/images/security-breach.png";
import DataDeletionImage from "src/modules/data-protection/assets/images/data-deletion.png";
import OtherRequests from "src/modules/data-protection/assets/images/OtherRequests.png";
import UserUtils from "../../../../../services/utils/UserUtils";
import { setCaseId } from "../../../../../store/actions";
import { withRouter } from "react-router-dom";
import ThirdPartyReportService from "../../../apis/ThirdPartyReportService";
import { EMPTY_LIST } from "./table-filter/constants";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Navigator from "../../../components/navigator/index";
import TableFilter from "./table-filter";

const MyReportList = (props) => {
  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    status: null,
    category: null,
    risk: null,
  });

  const { t } = props;

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);
  const [reportList, setReportList] = useState([]);
  const [categories, setCategories] = useState([]);

  const dateUtils = new DateUtils();
  const userUtils = new UserUtils();

  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(props?.currentUser);
  };


  const handleFetchCategories = useQuery({
    queryKey: ["report-fetch-categories"],
    queryFn: async () => {
      const service = ThirdPartyReportService.getInstance();

      return await service.FetchCategories();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(props.t("An error occurred while fetching categories."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (handleFetchCategories.data) {
      setCategories(handleFetchCategories.data);
    }
  }, [handleFetchCategories.data]);

  const handleRenderReportTypeIcon = (type) => {
    switch (type?.toLowerCase()) {
      case "data breach incident": {
        return (
          <div className="d-flex align-items-center">
            <img
              style={{ width: "24px", height: "24px", marginRight: 9 }}
              alt={type}
              className="rounded-circle card-img"
              src={SecurityBreachImage}
            />
            <span style={{ textTransform: "capitalize" }}>{t(type)}</span>
          </div>
        );
      }
      case "data deletion request": {
        return (
          <div className="d-flex align-items-center">
            <img
              style={{ width: "24px", height: "24px", marginRight: 9 }}
              alt={type}
              className="rounded-circle card-img"
              src={DataDeletionImage}
            />
            <span style={{ textTransform: "capitalize" }}>{t(type)}</span>
          </div>
        );
      }
      case "data subject request": {
        return (
          // <p className="dt-field">
          <div className="d-flex align-items-center">
            <img
              style={{ width: "24px", height: "24px", marginRight: 9 }}
              alt={type}
              className="rounded-circle card-img"
              src={OtherRequests}
            />
            <span style={{ textTransform: "capitalize" }}>{t(type)}</span>
          </div>
        );
      }
      default:{
        return (
          <div className="d-flex align-items-center">
            <span style={{ textTransform: "capitalize" }}>{type}</span>
          </div>
        );
      }
    }
  };

  const handleRenderReportStatus = (statusId) => {

    const newStatus = GDPR_REPORT_STATUS_LIST.find(
      (item) => item.id === statusId
    );
    return (
      <div
      style={{
        fontWeight: 600,
        padding: "6px 10px",
        background: newStatus?.backgroundColor,
        opacity: newStatus?.opacity || 1,
        color:"#1D1D1D",
        borderRadius:'7px',
        whiteSpace:'nowrap',
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)"
      }}
      className={"dt-task-status "}
    >
      {props.t(newStatus?.titleKey)}
    </div>
    );
  };

  const handleRenderReportCategories = (reportCategories) => {
    return reportCategories?.join(", ");
  };


  const columns = [
    {
      dataField: "type",
      text: props.t("Type"),
      sort: false,
      key: 2,
      formatter: (cellContent, row) => {
        return (
          <Link
            className="text-dark"
            to={`./report/detail`}
            onClick={() => {
              props.onSetCaseId(row.case_id);
              localStorage.setItem("caseId", row.case_id);
            }}
          >
            {handleRenderReportTypeIcon(row.questionnaire)}
          </Link>
        );
      },
      headerStyle: {
        width: "220px"
      },
      style: { 
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        verticalAlign: "middle",
      }
    },
    {
      dataField: "case_id",
      text: props.t("Case ID"),
      sort: false,
      style: { display: "table-cell", maxWidth: "200px" },
      headerStyle: { maxWidth: "200px" },
      key: 3,
      style: {
        verticalAlign: "middle" 
      },
      formatter: (cellContent, row) => {
        return (
          <Link
            className="text-dark"
            to={`./report/detail`}
            onClick={() => {
              props.onSetCaseId(row.case_id);
              localStorage.setItem("caseId", row.case_id);
            }}
          >
            {row.secondary_case_id}
          </Link>
        );
      },
    },
    {
      dataField: "description",
      text: props.t("Description"),
      sort: false,
      key: 4,
      style: {
        verticalAlign: "middle" 
      },
      formatter: (cellContent, row) => {
        return <div>{row.description ? (row.description.length > 70 ? `${row.description.substring(0, 70)}...` : row.description) : ''}</div>;
      },
    },
    {
      dataField: "category",
      text: props.t("Categories"),
      sort: false,
      style: { 
        display: "table-cell", 
        maxWidth: "600px", 
        verticalAlign: "middle",
        whiteSpace: "normal"  
      },
      headerStyle: { 
        maxWidth: "600px", 
        whiteSpace: "normal"  
      },
      key: 5,
      formatter: (cellContent, row) => {
        const categoriesText = handleRenderReportCategories(row?.categories);

        return (
          <div
            style={{
              maxWidth: "500px",
              whiteSpace: "normal", 
              padding: "5px", 
            }}
            title={categoriesText} 
          >
            {categoriesText}
          </div>)      
          },
    },
    {
      dataField: "submission_date",
      text: props.t("Submission date"),
      sort: false,
      key: 6,
      style: {
        verticalAlign: "middle" 
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            {dateUtils.convertTimeStampToDate(
              cellContent,
              INTERNATIONAL_DATE_FORMAT
            )}
          </div>
        );
      },
    },
    {
      dataField: "closing_date",
      text: props.t("Closing date"),
      sort: false,
      key: 7,
      style: {
        verticalAlign: "middle" 
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            {dateUtils.convertTimeStampToDate(
              cellContent,
              INTERNATIONAL_DATE_FORMAT
            )}
          </div>
        );
      },
    },
    {
      dataField: "analyst_assigned",
      text: props.t("Assigned analyst"),
      sort: false,
      key: 8,
      style: {
        verticalAlign: "middle" 
      },
      formatter: (cellContent, row) => {
        if (!cellContent || Array.isArray(cellContent)) return null;
        return (
          <div className="d-flex align-items-center gap-1"><span className='dt-list-col-bullet'>{cellContent.first_name[0]}</span>{' '}{`${cellContent.first_name} ${cellContent.last_name}`}</div>
        );
      },
    },
    {
      dataField: "status",
      text: props.t("Status"),
      sort: false,
      key: 9,
      style: {
        verticalAlign: "middle" 
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            {handleRenderReportStatus(row.status)}
            <div>
              <small className="text-muted">
                {props.t("Updated")}:{" "}
                {dateUtils.convertTimeStampToDate(
                  row.status_updating_date,
                  INTERNATIONAL_DATE_FORMAT
                )}
              </small>
            </div>
          </div>
        );
      },
    },
  ]
  const tableColumns = isAnalystOrAnalystAdmin() ? [{
    key: 1,
    dataField: "risk_type",
    text: props.t("Risk"),
    sort: false,
    formatter: (cellContent, row) => {
      return (
        <span
          style={{ backgroundColor: `#${row.risk}` }}
          className="risk-rate"
        ></span>
      );
    },
  }
  , ...columns] : columns;

  const NoDataIndication = () =>
    handleFetchReportListQuery.isFetched &&
    !handleFetchReportListQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {props.t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const handleFetchReportListQuery = useQuery({
    queryKey: ["report-data-subject-fetch-list", tableFilters],
    queryFn: async () => {
      const { status, category, risk } = tableFilters;
      const param = {
        ...(status !== undefined && { status }), // Add status if not null or undefined
        ...(category !== null && { category }), // Conditionally add category if not null
        ...(risk !== null && { risk }), // Conditionally add risk if not null
        pageIndex: tableFilters.pageIndex,
        pageSize: tableFilters.pageSize,
      };
      try {
        const response = await axios.post(`${API_URL_GET_REPORT_LIST}`, param, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });
        return response.data?.data || [];
      } catch (error) {
        throw new Error("An error occurred while fetching report list.");
      }
    },
    config: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast(props.t("An error occurred while fetching report list."), {
          type: "error",
        });
      },
    },
  });

  useEffect(() => {
    if (handleFetchReportListQuery.data) {
      setReportList(handleFetchReportListQuery.data.reports || []);
      setTableResultTotalCount(
        handleFetchReportListQuery.data?.itemsCount || 0
      );
    }
  }, [handleFetchReportListQuery.data]);

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};

    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        result[[tableFilterKey]] = filters[tableFilterKey];
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
      }
    }
    setTableFilters(result);
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    switch (type) {
      case "pagination":
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;
      default:
        return false;
    }
  };

  return (
    <div className="page-content">
      <Container fluid style={{ minHeight: "400px" }}>
        <Row>
          <Col lg="12" className="mb-2">
            <Navigator
              breadCrumbs={[
                { title: "SIP", link: "/" },
                {
                  title: "My reports",
                  link: `#`,
                },
              ]}
            />
          </Col>

          <Col lg="12">
            <div
              className="bg-white"
              style={{
                borderRadius: "7px",
                boxShadow: "0px 0px 4px 0px #4F666E40",
              }}
            >
              <div className="p-4" style={{ marginBottom: "300px" }}>
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    page: tableFilters.pageIndex,
                    sizePerPage: tableFilters.pageSize,
                    totalSize: tableResultTotalCount,
                    withFirstAndLast: false,
                    alwaysShowAllBtns: true,
                    prePageText: (
                      <span>
                        <i className="ri-arrow-left-s-line"></i>{" "}
                        {props.t("Back")}
                      </span>
                    ),
                    nextPageText: (
                      <span>
                        {props.t("Next")}{" "}
                        <i className="ri-arrow-right-s-line"></i>
                      </span>
                    ),
                    prePageTitle: props.t("Pre page"),
                    firstPageTitle: props.t("Next page"),
                    showTotal: true,
                    hideSizePerPage: false,
                    sizePerPageList: [
                      {
                        text: "25",
                        value: 25,
                      },
                      {
                        text: "50",
                        value: 50,
                      },
                    ],
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <React.Fragment>
                      <Row className="mb-5 mt-3">
                        <Col sm="12">
                          <div className="d-flex gap-4 justify-content-end">
                            <TableFilter
                              defaultValues={{
                                ...tableFilters,
                              }}
                              categories={categories}
                              onOkButtonClicked={onTableFilterOkButtonClicked}
                              type={props.type}
                              isAdmin={isAnalystOrAnalystAdmin()}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <BootstrapTable
                            remote={{
                              pagination: true,
                              filter: false,
                              sort: true,
                              cellEdit: false,
                              search: false,
                            }}
                            loading={
                              handleFetchReportListQuery.isFetching ||
                              handleFetchReportListQuery.isLoading ||
                              handleFetchCategories.isLoading ||
                              handleFetchCategories.isFetching
                            }
                            overlay={overlayFactory({
                              spinner: (
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  size="md"
                                />
                              ),
                              text: "Loading...",
                            })}
                            onTableChange={handleTableChange}
                            defaultSorted={[]}
                            keyField={"id"}
                            responsive
                            bordered={false}
                            data={reportList}
                            striped={true}
                            columns={tableColumns}
                            wrapperClasses="table-responsive"
                            classes={"table tpdd-table incident-table"}
                            headerWrapperClasses={"thead-light"}
                            style={{
                              overflowX: "auto",
                            }}
                            noDataIndication={() => <NoDataIndication />}
                            {...paginationTableProps}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12" md="6">
                          <div className="tpdd-pagination-style-1">
                            <PaginationListStandalone {...paginationProps} />

                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </PaginationProvider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { user:currentUser, token } = state.Login;
  const { App } = state;
  return { token, currentUser, App };
};

const mapDispatchToProps = (dispatch) => ({
  onSetCaseId: (caseId) => dispatch(setCaseId(caseId)),
});

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, mapDispatchToProps)(MyReportList))
);
