import anti_corruption from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/anti-corruption.svg';
import compliance_and_corporate_policy from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/compliance_and_corporate_policy.svg';
import environmental_standards from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/environmental_standards.svg';
import human_rights_due_diligence from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/human_rights_due_diligence.svg';
import relevant_certificates_and_audit_reports from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/relevant_certificates_and_audit_reports.svg';
import risk_management from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/risk_management.svg';
import transparency_and_reporting from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/transparency_and_reporting.svg';
import working_conditions from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/working_conditions.svg';
import final_declarations from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/final_declarations.svg';
import financials from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/financials.svg';
import applications_and_interface_security from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/applications_interface_security.png';
import audit_assurance_and_compliance from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/audit_assurance_and_compliance.png';
import business_continuity_management_and_operational_resilience from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/business_continuity_management_and_operational_resilience.png';
import change_management from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/change_management.png';
import data_security_and_information_lifecycle_management from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/data_security_and_information_lifecycle_management.png';
import data_center_security from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/data_center_security.png';
import encryption_and_key_management from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/encryption_and_key_management.png';
import governance_and_risk_management from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/governance_and_risk_management.png';
import human_resources from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/human_resources.png';
import identity_and_access_management from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/identity_and_access_management.png';
import infrastructure_and_virtualization_security from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/infrastructure_and_virtualization_security.png';
import interoperability_and_portability from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/interoperability_and_portability.png';
import security_incident_management_e_discovery_and_cloud_forensics from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/security_incident_management_e_discovery_and_cloud_forensics.png';
import supply_change_management_transparency_and_accountability from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/supply_change_management_transparency_and_accountability.png';
import threat_and_vulnerability_management from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/threat_and_vulnerability_management.png';
import external_certifications from 'src/modules/3rd-party-management/assets/images/questionnaire/sections/external_certifications.png';

import {
    HiChevronDoubleDown,
    HiChevronDoubleUp,
    HiMenuAlt4
} from "react-icons/hi";


export const QuestionnaireSectionsImages = {
    'anti_corruption'                           					:   anti_corruption,
    'compliance_and_corporate_policy'           					:   compliance_and_corporate_policy,
    'environmental_standards'                   					:   environmental_standards,
    'human_rights_due_diligence'                					:   human_rights_due_diligence,
    'relevant_certificates_and_audit_reports'   					:   relevant_certificates_and_audit_reports,
    'risk_management'                           					:   risk_management,
    'transparency_and_reporting'                					:   transparency_and_reporting,
    'working_conditions'                        					:   working_conditions,
    'final_declarations'                        					:   final_declarations,
    'financials'                                                    :   financials,
	'applications_and_interface_security'							:	applications_and_interface_security,
	'audit_assurance_and_compliance'								:	audit_assurance_and_compliance,
	'business_continuity_management_and_operational_resilience'		:	business_continuity_management_and_operational_resilience,
	'change_management'												:	change_management,
	'data_security_and_information_lifecycle_management'			:	data_security_and_information_lifecycle_management,
	'data_center_security'											:	data_center_security,
	'encryption_and_key_management'									:	encryption_and_key_management,
	'governance_and_risk_management'								:	governance_and_risk_management,
	'human_resources'												:	human_resources,
	'identity_and_access_management'								:	identity_and_access_management,
	'infrastructure_and_virtualization_security'					:	infrastructure_and_virtualization_security,
	'interoperability_and_portability'								:	interoperability_and_portability,
	'security_incident_management_e_discovery_and_cloud_forensics'	:	security_incident_management_e_discovery_and_cloud_forensics,
	'supply_change_management_transparency_and_accountability'		:	supply_change_management_transparency_and_accountability,
	'threat_and_vulnerability_management'							:	threat_and_vulnerability_management,
	'external_certifications'										:	external_certifications
}

export const HistoryLogActions = {
	case        :   {   
        create                      :   'New report created',
		update_analyst              :   'Update Analyst',
		update_status               :   'Update Status',
		mark_as_delete              :   'Marked as delete',
		delete                      :   'Deleted',
		upload_attachment           :   'Uploaded attachment',
		download_user_attachment    :   'Download user attachment',
		download_analyst_attachment :   'Download analyst attachment',
		delete_attachment           :   'Delete Attachment',
		add_comment                 :   'Add Comment',
		add_internal_comment        :   'Add Internal Comment',
		update_analyst_readonly     :   'Read-Only Analysts',
		update_risk                 :   'Risk updated',
		link_case                   :   'Linked case updated',
		change_admin_description    :   'Admin description updated',
		change_admin_category       :   'Admin category updated',
		add_link_case               :   "Linked case added",
		remove_link_case            :   "Linked case removed"
	},
	risk        :   {
		upload_files        :   'Uploaded files',
		upload_file         :   'Uploaded file',
		download_files      :   'Download files',
		download_file       :   'Download file',
		update_file         :   'Upload file',
		delete_file         :   'Delete file',
		create_comment      :   'New comment created',
		create              :   'New risk created',
		update_description  :   'Description updated',
		delete              :   'Deleted',
		assign_analyst      :   'Assigned to analyst',
		update_damage       :   'Damage updated',
		update_likelihood   :   'Likelihood updated',
		update_category     :   'Category updated',
		change_description  :   'Description updated by admin',
		change_category     :   'Category updated by admin',
		create_ai           :   'New Ai risk created',
	},
	task        :   {
		upload_files        :   'Uploaded files',
		upload_file         :   'Uploaded file',
		download_files      :   'Download files',
		download_file       :   'Download file',
		update_file         :   'Upload file',
		delete_file         :   'Delete file',
		create_comment      :   'New comment created',
		create              :   'New task created',
		assign_analyst      :   'Assigned to analyst',
		update_description  :   'Description updated',
		update_status       :   'Status updated',
		update_priority     :   'Priority updated',
		update_deadline     :   'Deadline updated',
		update_category     :   'Category updated',
		delete              :   'Deleted',
	},
	admin       :   {
		change_active_directory_settings    :   'Active directory settings',
		edit_risk_matrix                    :   'Update matrix',
		restore_risk_matrix                 :   'Restore matrix',
		edit_user                           :   'Edit user',
		create_user                         :   'Created user',
		delete_user                         :   'Deleted user',
		change_user_status                  :   'Changed user status',
		edit_organization                   :   'Edited organization',
		update_question                     :   'Updated question',
		remove_question                     :   'Removed question',
		remove_category                     :   'Removed category',
		login                               :   'Login',
	},
	document    :   { 
        create  :   'Created', 
        remove  :   'Removed' 
    },
	user        :   { 
        approveConsent  : "approve_consent" 
    },
    supplier    :   {
        'onboard'           :   'New supplier created',
        'first_assessment'  :   'First assessment based on flagging'
    }
};

export const HistoryLogSections = {
	risk        :   'Risk Management',
	task        :   'Task Management',
	admin       :   'Administration',
	case        :   'Report',
	document    :   'Document Management',
	user        :   "User",
    supplier    :   'Supplier'
};

export const HistoryLogRoles = {
	ROLE_ANALYST_ADMIN  :   'Analyst admin',
	ROLE_ANALYST        :   'Analyst',
	ROLE_TASK_OWNER     :   'Task Owner',
	ROLE_THIRD_PARTY    :   'Third Party',
	ROLE_EMPLOYEE       :   'Employee'
};

export const TasksStatuses = {
	NEW				:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	IN_PROGRESS		:	{
		title	:	'In Progress',
		color	:	'#8792E8'
	},
	REVIEW			:	{
		title	:	'Review',
		color	:	'#85FF8A'
	},
	CLOSED			:	{
		title	:	'Closed',
		color	:	'#EDECEC'
	},
	DELETED			:	{
		title	:	'Deleted',
		color	:	'#FFDADA'
	}
}

export const TasksPriorities = {
	low		:	{
		title	:	"Low",
		color	:	"#1CBB8C",
		icon	:	<HiChevronDoubleDown />
	},
	medium	:	{
		title	:	"Medium",
		color	:	"#FCC34B",
		icon	:	<HiMenuAlt4 />
	},
	high	:	{
		title	:	"High",
		color	:	"#DC3545",
		icon	:	<HiChevronDoubleUp />
	}
};

export const ReportsStatuses = {
	0	:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	1	:	{
		title	:	'Open',
		color	:	'#85FF8A'
	},
	2	:	{
		title	:	'Assigned',
		color	:	'#F693FF'
	},
	3	:	{
		title	:	'In Progress',
		color	:	'#8792E8'
	},
	4	:	{
		title	:	'Closed',
		color	:	'#EDECEC'
	},
	5	:	{
		title	:	'Pending Deletion',
		color	:	'#FFDADA'
	},
	6	:	{
		title	:	'Deleted',
		color	:	'#FFDADA'
	},
}

export const SuppliersStatuses = {					
	0	:	{
		title	:	'New',
		color	:	'#FFEE93'
	},
	1	:	{
		title	:	'Under Review',
		color	:	'#74B8FF'
	},
	2	:	{
		title	:	'Approved',
		color	:	'#4CD4AC'
	},
	3	:	{
		title	:	'Rejected',
		color	:	'#BE4F59'
	}
}

export const CompanySizes = {
	"0-10"	:	{
		title	:	"0-10"
	},
	"10-50"	:	{
		title	:	"10-50"
	},
	"50-100"	:	{
		title	:	"50-100"
	},
	"100-500"	:	{
		title	:	"100-500"
	},
	"500-1000"	:	{
		title	:	"500-1000"
	},
	"1000-2000"	:	{
		title	:	"1000-2000"
	},
	"2000-5000"	:	{
		title	:	"2000-5000"
	},
	"+5000"		:	{
		title	:	"+5000"
	}
};

export const RisksRates = {
    "low": {
        title: "Low",
        color: "#1CBB8C"
    },
    "medium": {
        title: "Medium",
        color: "#FCC34B"
    },
    "high": {
        title: "High",
        color: "#DC3545"
    },

    
    "insignificant": {
        title: "Insignificant",
        color: "#4EB8B1"
    },
    "minor": {
        title: "Minor",
        color: "#59C94F"
    },
    "noticeable": {
        title: "Noticeable",
        color: "#FFA049"
    },
    "critical": {
        title: "Critical",
        color: "#FF4949"
    },
    "existence - threatening": {
        title: "Existence - threatening",
        color: "#822626"
    },
}

export const SupplierStatuses = {
    "new"   :   {
        title   :   "New",
        color   :   "bg-secondary"
    },
    "under review"    :   {
        title   :   "Under Review",
        color   :   "bg-info"
    },
    "approved"  :   {
        title   :   "Approved",
        color   :   "bg-success"
    },
    "rejected"  :   {
        title   :   "Rejected",
        color   :   "bg-danger"
    }
}

export const customFonts = [
    {
      style: "normal",
      family: "Inter",
      weight: 400,
    },
    {
      style: "normal",
      family: "Nunito",
      weight: 400,
    },
    {
      style: "normal",
      family: "Raleway",
      weight: 400,
    },
    {
      style: "normal",
      family: "Roboto Mono",
      weight: 400,
    },
    {
      style: "normal",
      family: "Noto Serif Toto",
      weight: 400,
    },
    {
      style: "normal",
      family: "Kumbh Sans",
      weight: 400,
    },
    {
      style: "normal",
      family: "Padyakke Expanded One",
      weight: 400,
    },
    {
      style: "normal",
      family: "Noto Sans Cherokee",
      weight: 400,
    },
  ];