import React, { Fragment, useEffect, useState, useMemo } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardSubtitle,
	CardText,
	CardTitle,
	Col,
	Collapse,
	Row,
    UncontrolledPopover,
	PopoverHeader,
	PopoverBody,
    Modal, 
    ModalBody, 
    ModalHeader,
    Alert,
    ModalFooter
} from "reactstrap";
import risk_ai from "src/assets/images/AI pic for SIP.png";
import risk_ai_processing from "src/assets/images/AI_process.png";
import { GoTag } from "react-icons/go";
import { CiFlag1 } from "react-icons/ci";
import { FaChevronRight } from "react-icons/fa";
import { TbLoader } from "react-icons/tb";
import {
  CREATE_RISK_FAILED,
  CREATE_RISK_SUCCESSFUL,
} from "src/common/constants";

import AIRiskModal from "./components/AIRiskModal";
import AIRiskRightModal from "./components/AIRiskRightModal";
import AIRiskDetailsModal from "./components/AIRiskDetailsModal";
import { toast } from "react-toastify";
import RedundancyIcon from "src/modules/3rd-party-management/assets/images/overlap.png";

const AssessmentModal = function ({
    t, 
    token,
    isOpen, 

    aiRequestOptions,
    aiTranlateRequestOptions,
    additionnalDetailsRequestOptions,
    createRisksRequestOptions, 
    translateResult = true,
    onRisksCreated, 
    closeModal, 
    children 
}) {
    const [AIRisks, setAIRisks] = useState(null);
    const [explanation, setExplanation] = useState(null);
    const [collapse, setCollapse] = useState(0);
    const [regenerate, setRegenrate] = useState(false);
    const [showProcessModal, setShowProcessingModal] = useState(false);
    const [showRightModal, setShowRightModal] = useState(false);
    const [showDetailstModal, setShowDetailsModal] = useState(false);
    const [selectedRiskDetails, setSelectedRiskDetails] = useState({});
    const [createAIProgress, setCreateAIProgress] = useState(false);
    const [redundancyAlertModalItem, setRedundancyAlertModalItem] = useState(null);
    const [redundantNotifiedRisks, setRedundantNotifiedRisks] = useState([]);

    const handleRightModal = () => setShowRightModal(!showRightModal);

    const toggleDetailsModal = () => {
        if (showDetailstModal) {
        setSelectedRiskDetails({});
        }
        setShowDetailsModal(!showDetailstModal);
    };

    const risksColors = {
        'strategic risk'                :   'rgba(146, 219, 184, 1)',
        'operational risk'              :   'rgba(184, 148, 212, 1)',
        'financial risk'                :   'rgba(224, 152, 143, 1)',
        'employees and management risk' :   'rgba(208, 150, 125, 1)',
        'unknown'                       :   'rgba(140, 147, 208, 1)'
    };

    const subTaksColors = {
        'strategic risk'                    :   'rgba(146, 219, 184, 0.5)',
        'operational risk'                  :   'rgba(184, 148, 212, 0.5)',
        'financial risk'                    :   'rgba(224, 152, 143, 0.5)',
        'employees and management risk'     :   'rgba(208, 150, 125, 1)',
        'unknown'                           :   'rgba(140, 147, 208, 0.5)'
    }

    const [flaggedRisks, setFlaggedRisks] = useState([]);
    const [flaggedMitTasks, setFlaggedMitTasks] = useState([]);
    const [translationFlag, setTranslationFlag] = useState(false);

    const riskColor = (riskType) => {
        return risksColors[riskType.toLowerCase()] || risksColors['unknown'];
    };

    const subTaskColor = (riskType) => {
        return subTaksColors[riskType.toLowerCase()] || subTaksColors['unknown'];
    };

    const groupRisksByType = (risks) => {
        return risks.reduce((acc, risk) => {
            acc[risk.riskType] = acc[risk.riskType] || [];
            acc[risk.riskType].push(risk);
            return acc;
        }, {});
    };

    const getHelpFromAI = async () => {
        try {
            const response = await axios.post(aiRequestOptions.requestUrl,
                {
                    regenerate_risk_ai  :   regenerate,
                    ...(aiRequestOptions?.payload || {})
                },
                {
                    headers: {
                        Authorization   : `Bearer ${ token }`
                    }
                }
            );

            if (response.status === 200) {
                if(translateResult){
                    setTranslationFlag(true);

                    const responseTr = await axios.post(
                        aiTranlateRequestOptions.requestUrl,
                        {
                            ...(aiTranlateRequestOptions?.payload || {})
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${ token }`,
                            }
                        }
                    );

                    if (responseTr.status === 200 && "risks" in responseTr.data.data) {
                        setAIRisks(
                            Object.values(responseTr.data.data.risks)
                        );

                        setExplanation(responseTr.data.data.explanation);

                        setRegenrate(false);

                        setTranslationFlag(false);
                    }
                }
                else if("risks" in response.data.data){
                    setAIRisks(
                        Object.values(response.data.data.risks || [])
                    );

                    setExplanation(response.data.data.explanation || []);

                    setRegenrate(false);

                    setTranslationFlag(false);
                }
            }
        } 
        catch (error) {
            toast(t('Failed to fetch risks.'), {
				type: 'error',
			});

            closeModal && closeModal();

            setTranslationFlag(false);
        }
    };

    const onSubmitClick = async () => {
        const previouslyCreatedRisks = AIRisks.filter((risk) => risk.flag).map((risk) => risk.riskId);

        const totalFlaggedRisks = flaggedRisks.concat(previouslyCreatedRisks);
 
        if (!totalFlaggedRisks?.length) {
            return toast(t('Please select at least one risk.'), {
				type: 'error',
			});
        }

        setCreateAIProgress(true);

        const selectedDetailsRisk = [];

        totalFlaggedRisks.forEach((r) => selectedDetailsRisk.push({
            riskId: r
        }));

        axios.post(additionnalDetailsRequestOptions.requestUrl, {
            ...selectedDetailsRisk,
            ...(additionnalDetailsRequestOptions?.payload || {})
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((res) => {
            if (res) {
                const risksDetails = res?.data?.data || [];
                if(risksDetails.length > 0){
                    const selectedRisks = [];

                    totalFlaggedRisks.forEach((selectedRiskId) => {
                        const riskBaseInfos = AIRisks.find((risk) => risk.riskId === selectedRiskId);
                        const riskDetails = risksDetails.find((detail) => detail.riskId === selectedRiskId)

                        if(riskDetails && riskBaseInfos){
                            const selectedTasks = [];

                            riskBaseInfos.mitigation_tasks.forEach((task) => {
                                if (flaggedMitTasks.findIndex((t) => t === task.id) > -1) {
                                    selectedTasks.push({
                                        chatbotId   : task.id,
                                        name        : task.title,
                                        description : task.description,
                                    });
                                }
                            });

                            if(riskBaseInfos.flag && !selectedTasks.length){
                                return
                            }

                            const { likelihood, damage, location, subCategory } = riskDetails;

                            if(selectedRisks.findIndex((riskToCompare) => riskToCompare.id === riskBaseInfos.riskId) < 0){
                                selectedRisks.push({
                                    description : riskBaseInfos.risks,
                                    likelihood  : likelihood,
                                    damage      : damage, 
                                    location    : location,
                                    category    : subCategory,
                                    tasks       : selectedTasks,
                                    id          : riskBaseInfos.riskId,
                                    chatbotId   : riskBaseInfos.riskId
                                });
                            }
                        }
                    });

                    const data = {
                        risks         : selectedRisks.map(({id, ...rest}) => rest),
                        ...(createRisksRequestOptions?.payload || {})
                    };
                    
                    axios.post(createRisksRequestOptions.requestUrl, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then((res) => {
                        if (res) {
                            toast(t(CREATE_RISK_SUCCESSFUL), {
                                type: 'success',
                            });

                            closeModal && closeModal();
                            
                            onRisksCreated && onRisksCreated();
                        }

                        setCreateAIProgress(false);
                    }).catch((error) => {
                        setCreateAIProgress(false);

                        toast(t(CREATE_RISK_FAILED), {
                            type: 'error',
                        });
                    });
                }
                else{
                    toast(t(CREATE_RISK_FAILED), {
                        type: 'error',
                    });
                }
            }
        }).catch((error) => {
            setCreateAIProgress(false);

            toast(t(CREATE_RISK_FAILED), {
                type: 'error',
            });
        });
    };

    const toggle = (id) => {
        setCollapse(id);
    };

    useEffect(() => {
        if(redundancyAlertModalItem){
            setRedundantNotifiedRisks([...redundantNotifiedRisks, redundancyAlertModalItem.riskId])
        }
    }, [ redundancyAlertModalItem ])

    useEffect(() => {
        if (isOpen) {
            getHelpFromAI();
        } 
        else {
            setTimeout(() => {
                setAIRisks(null);
            }, 300);
        }
    }, [isOpen]);

    useEffect(() => {
        if (regenerate) {
            setAIRisks(null);
            getHelpFromAI();
        }
    }, [regenerate]);

    const groupedAIRisks = useMemo(
        () => groupRisksByType(AIRisks || []),
        [AIRisks]
    );

    const onFlagRisk = (risk) => {
        if(risk.flag){
            toast(t('This risk has already been created and you can not delete it from here.'), {
                type: 'error',
            });
        }
        else{
            const id = risk.riskId;
            setFlaggedRisks((prevFlaggedRisks) => {
                const foundRisk = prevFlaggedRisks.includes(id);
                if (!foundRisk) {
                    if(risk.redundancy && !redundantNotifiedRisks.includes(id)){
                        setRedundancyAlertModalItem(risk);
                    }
                    return [...prevFlaggedRisks, id];
                } else {
                    setFlaggedMitTasks((prevFlaggedMitTasks) => {
                        const riskMitTasksIds = risk.mitigation_tasks.map((t) => t.id);

                        return [
                            ...prevFlaggedMitTasks.filter((prevTaskId) => {
                                return !riskMitTasksIds.includes(prevTaskId)
                            })
                        ]
                    });

                    return prevFlaggedRisks.filter((item) => item !== id);
                }
            });
        }
    };

    const onFlagMitTask = (mitTask, riskId) => {
        if(mitTask.flag){
            toast(t('This task has already been created and you can not delete it from here.'), {
                type: 'error',
            });
        }
        else{
            const id = mitTask.id;

            if (!AIRisks.find((risk) => risk.flag && risk.riskId === riskId) && !flaggedRisks.includes(riskId)) {
                toast(t('Please select the risk'), {
                    type: 'error',
                });
            } 
            else {
                setFlaggedMitTasks((prevFlaggedMitTasks) => {
                    const foundTask = prevFlaggedMitTasks.includes(id);
                    if (!foundTask) {
                        return [...prevFlaggedMitTasks, id];
                    } 
                    else {
                        return prevFlaggedMitTasks.filter((item) => item !== id);
                    }
                });
            }
        }
    };

    const onRegenerateClick = () => {
        setRegenrate(true);
    };

    const truncateHtmlText = (html, maxChars = 250) => {
        return html?.length > maxChars ? html.slice(0, maxChars) + "..." : html;
    };

    const truncatedExplanation = truncateHtmlText(explanation);

    const processHtmlContent = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");

        // Convert h1 to h2, h2 to h3, h3 to h4
        doc.querySelectorAll("h1, h2, h3").forEach((header) => {
            switch (header.tagName) {
                case "H1":
                    header.outerHTML = `<h2>${header.innerHTML}</h2>`;
                break;
                case "H2":
                    header.outerHTML = `<h3>${header.innerHTML}</h3>`;
                break;
                case "H3":
                    header.outerHTML = `<h4>${header.innerHTML}</h4>`;
                break;
                default:
                break;
            }
        });

        return doc.body.innerHTML;
    };

    const renderResult = () => {
        return (
            <React.Fragment>
                <Row className="sticky-row">
                    <Col lg="10" md="6" sm="12">
                        <h2 className="mt-4 assessment-title">
                            {t("Risk Assesment Support by AI")}
                        </h2>
                    </Col>
                    <Col lg="2" md="6" sm="12">
                        <div className="mt-3" style={{display: "flex"}}>
                            <Button color="primary" 
                                onClick={onRegenerateClick} 
                                disabled={createAIProgress} 
                                className="me-2" 
                                outline>
                                {t("Regenerate")}
                            </Button>

                            <Button color="primary" onClick={onSubmitClick} style={{display: "flex"}} disabled={createAIProgress}>
                                {createAIProgress ? 
                                    <TbLoader /> :
                                    <>
                                        {t("Next")} 
                                        <FaChevronRight className="ps-2 pt-2" />
                                    </>
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>

                {AIRisks && (
                    <div className="ai-risks-list">
                        <Row>
                            <Col lg={3} md={3} sm={6}>
                                <img src={risk_ai} alt=""
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        maxWidth: "60%",
                                    }}
                                    className="pt-3 p-3"
                                />

                                <div className="darkBlueText" style={{ maxHeight: "30rem" }}>
                                    {explanation && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                        __html: processHtmlContent(truncatedExplanation),
                                        }}
                                    />
                                    )}

                                    <Button className="bg-primary" onClick={handleRightModal}>
                                    {t("Learn More")}
                                    </Button>
                                </div>
                            </Col>

                            <Col lg={9}>
                                <Row style={{ maxHeight: "50rem", overflowY: "scroll" }}>
                                    {Object.keys(groupedAIRisks).map(
                                        (riskType, index) => {
                                            return (
                                                <>
                                                    <Col lg={AIRisks.length > 2 ? 4 : 6} 
                                                        md={4} 
                                                        key={index}
                                                        className="mb-5"
                                                        style={{
                                                            height: "35rem",
                                                            overflowY: "scroll",
                                                        }}>
                                                            {groupedAIRisks[riskType].map((risk) => {
                                                                const riskType = risk?.riskType ? risk.riskType : risk?.title?.split(' ').slice(0, 2).join(' ');
                                                                const { riskId, redundancy, reasons } = risk;
                                                                const rColor = riskColor(riskType);
                                                                const subTColor = subTaskColor(riskType);
                                                                const previouslySelected = !!risk.flag

                                                                return (
                                                                    <>
                                                                        <Card
                                                                            style={{
                                                                                backgroundColor: "#fafafa",
                                                                            }}>
                                                                            <CardBody className="cardBody">
                                                                                <CardTitle
                                                                                    tag="h4"
                                                                                    className="d-flex justify-content-start align-items-center">
                                                                                        {redundancy && (
                                                                                            <div className="redundancy-icon" id={`redundancy-btn-${riskId}`}>
                                                                                                <img src={RedundancyIcon} alt="redundancy" style={{
                                                                                                    width: '24px'
                                                                                                }}/>
                                                                                            </div>
                                                                                        )}
                                                                                        <div className="d-flex justify-content-between align-items-center" style={{
                                                                                            marginLeft: '12px',
                                                                                            width: '90%'
                                                                                        }}>
                                                                                            <span style={{ backgroundColor: rColor }}
                                                                                                className="category me-1">
                                                                                                <GoTag /> 
                                                                                                {t(riskType)}
                                                                                            </span>
                                                                                            <span className={
                                                                                                previouslySelected || flaggedRisks.includes(riskId)
                                                                                                    ? "selectedFlag"
                                                                                                    : "flag"
                                                                                                }
                                                                                                onClick={() => onFlagRisk(risk)}>
                                                                                                <CiFlag1 />
                                                                                            </span>
                                                                                        </div>
                                                                                        {redundancy && (
                                                                                            <UncontrolledPopover
                                                                                                placement="bottom"
                                                                                                target={`redundancy-btn-${riskId}`}
                                                                                                trigger="hover"
                                                                                            >
                                                                                                <PopoverHeader className="bg-warning">
                                                                                                    {t('Redundancy Alert')}
                                                                                                </PopoverHeader>
                                                                                                <PopoverBody>
                                                                                                    {reasons.length > 1 ? (
                                                                                                        <ol>
                                                                                                            {reasons.map(({ reason }) => <li className="mb-1">{reason}</li>)}
                                                                                                        </ol>
                                                                                                    ) : (
                                                                                                        <>{reasons.map(({ reason }) => reason)}</>
                                                                                                    )}
                                                                                                </PopoverBody>
                                                                                            </UncontrolledPopover>
                                                                                        )}
                                                                                        
                                                                                </CardTitle>

                                                                                <hr></hr>

                                                                                <CardSubtitle className="riskSubHeader mb-2 text-muted"
                                                                                tag="h5">
                                                                                    <span className="darkBlueText">
                                                                                        {t("Risks") + ":"}
                                                                                    </span>

                                                                                    <Button
                                                                                        className="bg-primary"
                                                                                        onClick={() => {
                                                                                            setSelectedRiskDetails(risk);
                                                                                            setShowDetailsModal(true);
                                                                                        }}
                                                                                        size="sm">
                                                                                        {t("Learn More")}
                                                                                    </Button>

                                                                                </CardSubtitle>

                                                                                <CardText>
                                                                                    <p>{t(risk?.description)}</p>
                                                                                    <p>{t(risk?.risks)}</p>

                                                                                    <div>
                                                                                        <h5>{t("Mitigation tasks") + ":"}</h5>
                                                                                        {risk.mitigation_tasks.map(
                                                                                        (mitTask, index) => {
                                                                                            const taskId = mitTask.id;
                                                                                            const previouslySelectedTask = !!mitTask.flag

                                                                                            return (
                                                                                                <Card style={{ marginBottom: "1rem" }}
                                                                                                    key={index}>
                                                                                                    <div>
                                                                                                        <CardHeader
                                                                                                            onClick={() => toggle(mitTask.id)}
                                                                                                            data-event={index}
                                                                                                            style={{
                                                                                                            cursor: "pointer",
                                                                                                            display: "flex",
                                                                                                            justifyContent: "space-between",
                                                                                                            backgroundColor: subTColor,
                                                                                                            }}>
                                                                                                            <span>{t(mitTask.title)}</span>

                                                                                                            <span
                                                                                                            className={
                                                                                                                previouslySelectedTask || flaggedMitTasks.includes(
                                                                                                                taskId
                                                                                                                )
                                                                                                                ? "selectedFlag"
                                                                                                                : "mitFlag"
                                                                                                            }
                                                                                                            onClick={() => onFlagMitTask(mitTask, riskId)
                                                                                                            }
                                                                                                            >
                                                                                                            <CiFlag1 />
                                                                                                            </span>
                                                                                                        </CardHeader>
                                                                                                    </div>
                                                                                                    <Collapse isOpen={collapse === mitTask.id}>
                                                                                                        <CardBody>
                                                                                                            {t(mitTask.description)}
                                                                                                        </CardBody>
                                                                                                    </Collapse>
                                                                                                </Card>
                                                                                            );
                                                                                        }
                                                                                        )}
                                                                                    </div>
                                                                                </CardText>
                                                                            </CardBody>
                                                                        </Card>
                                                                        <hr></hr>
                                                                    </>
                                                                );
                                                            })}
                                                    </Col>
                                                    {index >1 && <hr></hr>}
                                                </>
                                            )
                                        }
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
            </React.Fragment>
        );
    }

    const displayRedundancyAlertModal = !!redundancyAlertModalItem;
    const redundancyModalReasons = redundancyAlertModalItem?.reasons || [];

    return (
        <Fragment>
            {showRightModal && explanation && (
                <AIRiskRightModal
                    handleModal={handleRightModal}
                    showModal={showRightModal}
                    explanation={explanation}
                />
            )}

            {showDetailstModal && (
                <AIRiskDetailsModal
                    handleModal={toggleDetailsModal}
                    showModal={showDetailstModal}
                    explanation={explanation}
                    selectedRiskDetails={selectedRiskDetails}
                    flaggedRisks={flaggedRisks}
                    onFlagRisk={onFlagRisk}
                    flaggedMitTasks={flaggedMitTasks}
                    onFlagMitTask={onFlagMitTask}
                    rColor={riskColor(selectedRiskDetails?.riskType)}
                    subTColor={subTaskColor(selectedRiskDetails?.riskType)}
                />
            )}

            <Modal size="lg" isOpen={!!displayRedundancyAlertModal} toggle={() => setRedundancyAlertModalItem(null)}>
                <ModalHeader>
                    <div className="d-flex align-items-center justify-content-start">
                        <img src={RedundancyIcon} alt="redundancy" className="me-2" style={{width: '24px'}}/>
                        {t('Redundancy Alert')}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Alert color="warning" className="d-flex justify-content-start">
                        <div className="d-flex align-items-center me-2">
                            <i className="ri-alert-line" style={{fontSize: '32px'}}/>
                        </div>
                        <div className="d-flex align-items-start justify-content-center flex-column">
                            <strong>{t('Attention')}</strong>
                            {t('Please note that this risk can has redundancy issue with the risks listed below!')}
                        </div>
                    </Alert>
                    {redundancyModalReasons.length > 1 ? (
                        <ol>
                            {redundancyModalReasons.map(({ reason }) => <li className="mb-2">{reason}</li>)}
                        </ol>
                    ) : (
                        <>{redundancyModalReasons.map(({ reason }) => reason)}</>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setRedundancyAlertModalItem(null)} color="primary">
                        {t('OK')}
                    </Button>
                </ModalFooter>
            </Modal>

            {!AIRisks &&
                <AIRiskModal
                    modal={true}
                    toggle={() => setShowProcessingModal(!showProcessModal)}
                    imageSrc={risk_ai_processing}
                />
            }
            {!showProcessModal && AIRisks && (
                <>
                    {
                        children ? (
                            <>{ children(renderResult()) }</>
                        ) : (
                            <>{ renderResult() }</>
                        )
                    }
                </>
            )}
        </Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    const { token } = state.Login;

    return {
        user            :   state.Login.user,
        organization    :   Organization,
        token       
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )(AssessmentModal)
);