import { useMutation } from "@tanstack/react-query";
import React, { useState, useEffect, memo } from "react";
import { Accordion } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Input, Label, Col, Row, Progress, UncontrolledPopover, PopoverBody } from "reactstrap";
import DataProtectionTomService from "src/modules/data-protection/apis/DataProtectionTomService";
import debounce from "lodash/debounce";
import MeasuresList from "../measures-list";

const TOMItem = (props) => {
  const { t, section, index, handleFetchDetails } = props;

  const service = DataProtectionTomService.getInstance();

  const { title, sub_sections, info } = section;

  const [completedQuestions, setCompletedQuestions] = useState({});
  const [percentage, setPercentage] = useState(0);

  /**************************************************** FUNCTIONS ***************************************************** */
  const updateProgressBar = () => {
    const totalQuestions = Object.keys(completedQuestions)?.length;
    const completedCount =
      Object.values(completedQuestions).filter(Boolean)?.length;
    const completionPercentage = Math.round(
      (completedCount / totalQuestions) * 100
    );
    setPercentage(completionPercentage);
  };

  const handleInputChange = (name) => (e) => {
    setCompletedQuestions((prev) => ({
      ...prev,
      [name]: e.target.checked,
    }));
  };

  /********************************** Toggle Show On PDF ***************************************** */
  const changeToggleShowPDFMutation = useMutation({
    mutationFn: async (id) => {
      return await service.changeToggleShowPDF(id);
    },
    onSuccess: () => {
      toast(t("PDF export information was successfully updated."), {
        type: "success",
      });
    },
    onError: (error) => {
      toast(t("Failed to update PDF export information. Please try again."), {
        type: "error",
      });
    },
  });

  const changeToggleShowPDF = (id) => {
    changeToggleShowPDFMutation.mutate(id);
  };

  /********************************* Information *************************************** */
  const changeInformationMutation = useMutation({
    mutationFn: async (data) => {
      return await service.updateInformation(
        {
          information: data.information,
        },
        data.id
      );
    },
    onSuccess: () => {
      toast(t("Information successfully changed."), {
        type: "success",
      });
    },
    onError: (error) => {
      toast(t("An error occurred while changing Information."), {
        type: "error",
      });
    },
  });

  const debouncedHandleInformationChange = debounce((informationEvent, id) => {
    const newInformation = informationEvent.target.value;
    changeInformationMutation.mutate({
      id: id,
      information: newInformation,
    });
  }, 700);

  /********************************** Toggle Measure ***************************************** */
  const toggleMeasureMutation = useMutation({
    mutationFn: async (id) => {
      return await service.toggleMeasure(id);
    },
    onSuccess: () => {
      toast(t("Selected measure was successfully updated."), {
        type: "success",
      });
    },
    onError: (error) => {
      toast(t("An error occurred while updating selected measure."), {
        type: "error",
      });
    },
  });

  const toggleMeasure = (id) => {
    toggleMeasureMutation.mutate(id);
  };

  const addMeasureMutation = useMutation({
    mutationFn: async (newMeasure) => {
      return await service.addMeasure(newMeasure);
    },
    onSuccess: () => {
      toast(t("New measure added successfully."), {
        type: "success",
      });
      handleFetchDetails.refetch();
    },
    onError: (error) => {
      toast(t("An error occurred while adding the measure."), {
        type: "error",
      });
    },
  });

  const onAddNewMeasure = (newMeasureTitle, measureType, subSectionId) => {
    const newMeasure = {
      title: newMeasureTitle,
      type: measureType,
      subSection: subSectionId,
    };
    addMeasureMutation.mutate(newMeasure);
  };

  /********************************* Description *************************************** */
  const changeDescriptionMutation = useMutation({
    mutationFn: async (data) => {
      return await service.updateDescription(
        {
          description: data.description,
        },
        data.id
      );
    },
    onSuccess: () => {
      toast(t("Description successfully changed."), {
        type: "success",
      });
    },
    onError: (error) => {
      toast(t("An error occurred while changing description."), {
        type: "error",
      });
    },
  });

  const debouncedHandleDescriptionChange = debounce((descriptionEvent, id) => {
    const newDescription = descriptionEvent.target.value;
    changeDescriptionMutation.mutate({
      id: id,
      description: newDescription,
    });
  }, 700);

  /******************************************* USE EFFECTS ********************************************************** */
  useEffect(() => {
    const initializeCompletedQuestions = () => {
      const initialState = {};
      sub_sections.forEach((sub_section, sub_index) => {
        initialState[`showOnPDF_${sub_index}`] = sub_section.is_show_on_pdf;
        initialState[`information_${sub_index}`] = !!sub_section.information;
        initialState[`measures_${sub_index}`] = sub_section.measures.every(
          (measure) => measure.is_checked
        );
        initialState[`description_${sub_index}`] = !!sub_section.description;
      });
      setCompletedQuestions(initialState);
    };

    initializeCompletedQuestions();
  }, [sub_sections]);

  useEffect(() => {
    updateProgressBar();
  }, [completedQuestions]);

  return (
    <Row>
      <Col sm="12">
        <Accordion defaultActiveKey="0">
          <Accordion.Item className="border-none" eventKey="0">
            <Accordion.Header>
              <div className="p-1 accordion_progress">
                <div className="d-flex justify-content-center align-items-center">
                    {`${index + 1}. ${t(title)}`}{" "}
                    {info && (
                        <>
                            <span id={`section-info-button-${index}`} className="section-info-button font-size-24">
                                <i className="ri-information-line" />
                            </span>

                            <UncontrolledPopover
                                placement="bottom"
                                trigger='hover'
                                target={`section-info-button-${index}`}
                            >
                                <PopoverBody>
                                    <span>{t(info)}</span>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </>
                    )}
                </div>
                <div className="inner_progress">
                  <Progress
                    bar
                    className="my-1 progress"
                    animated
                    value={percentage}
                    color={
                      percentage === 100
                        ? "success"
                        : percentage === "0"
                        ? ""
                        : "warning"
                    }
                  />
                  <span className="progress_label">{`${percentage}%`}</span>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {sub_sections?.map((sub_section, sub_index) => {
                const {
                  title,
                  is_show_on_pdf,
                  information,
                  measures,
                  description,
                  id,
                  info : sub_info
                } = sub_section;
                return (
                  <Row key={id}>
                    {/* Sub Section Title */}
                    <Row>
                      {
                        <h5 className="d-flex justify-content-start align-items-center">
                            {`${index + 1}.${sub_index + 1} ${t(title)}`}
                            {sub_info && (
                                <>
                                    <span id={`section-info-button-${index}-${sub_index}`} className="section-info-button font-size-24">
                                        <i className="ri-information-line" />
                                    </span>

                                    <UncontrolledPopover
                                        placement="bottom"
                                        trigger='hover'
                                        target={`section-info-button-${index}-${sub_index}`}
                                    >
                                        <PopoverBody>
                                            <span>{t(sub_info)}</span>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </>
                            )}
                        </h5>
                        }
                    </Row>

                    {/* Show On PDF */}
                    <Row className="p-4">
                      <div>
                        <Input
                          type="checkbox"
                          defaultChecked={is_show_on_pdf}
                          onChange={() => {
                            handleInputChange(`showOnPDF_${sub_index}`);
                            changeToggleShowPDF(id);
                          }}
                          className="me-2"
                        />

                        {t("Show information in PDF export.")}
                      </div>
                    </Row>

                    {/* Information */}
                    <Row>
                      <Label
                        className="form-label"
                        htmlFor={`information_${sub_index}`}
                      >
                        {t("Information")}
                      </Label>
                      <Input
                        type="textarea"
                        id={`information_${sub_index}`}
                        className="m-3"
                        defaultValue={t(information)}
                        style={{ height: "7rem" }}
                        onChange={(e) => {
                          handleInputChange(`information_${sub_index}`);
                          debouncedHandleInformationChange(e, id);
                        }}
                      />
                    </Row>

                    {/* Measures */}
                    <Row className="pt-4 pb-4">
                      <MeasuresList
                        measures={measures}
                        measureType="technical"
                        handleInputChange={handleInputChange}
                        toggleMeasure={toggleMeasure}
                        t={t}
                        subSectionId={id}
                        onAddNewMeasure={onAddNewMeasure}
                      />
                      <MeasuresList
                        measures={measures}
                        measureType="organizational"
                        handleInputChange={handleInputChange}
                        toggleMeasure={toggleMeasure}
                        t={t}
                        onAddNewMeasure={onAddNewMeasure}
                        subSectionId={id}
                      />
                    </Row>

                    {/* Description */}
                    <Row>
                      <Label
                        className="form-label"
                        htmlFor={`description_${sub_index}`}
                      >
                        {t("Description")}
                      </Label>
                      <Input
                        type="textarea"
                        id={`description_${sub_index}`}
                        defaultValue={t(description)}
                        className="m-3"
                        style={{ height: "7rem" }}
                        onChange={(e) => {
                          handleInputChange(`description_${sub_index}`);
                          debouncedHandleDescriptionChange(e, id);
                        }}
                      />
                    </Row>
                  </Row>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;

  return {
    Organization,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(TOMItem)));
