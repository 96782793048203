import React, { useEffect, useState } from "react";
import { Document, Page, View, StyleSheet, Font } from "@react-pdf/renderer";
import FirstPageContent from "./FirstPage/first-page";
import axios from "axios";
import { API_URL_FETCH_RISK_LOCATIONS } from "src/modules/data-protection/constants";
import DateUtils from "src/services/utils/DateUtils";
import FirstHeader from "./FirstPage/firstHeader";
import FirstFooter from "./FirstPage/firstFooter";
import PageContent from "./OtherPages/page-content";
import Header from "./OtherPages/header";
import {
    FONT_OPEN_SANS_BOLD,
    FONT_OPEN_SANS_REGULAR,
  } from "src/components/constants";

Font.register({
    family: "Open Sans",
    fonts: [
        {
            src: FONT_OPEN_SANS_REGULAR,
            fontWeight: 400,
            fontStyle: "normal",
        },
        {
            src: FONT_OPEN_SANS_BOLD,
            fontWeight: 600,
            fontStyle: "bold",
        }
    ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Open Sans",
    flexDirection: "column",
    backgroundColor: "#FFFFFF"
  },
  section: {
    flexGrow: 1
  },
});

const PdfDocument = (props) => {
  const { data, organization } = props;
  const { primaryLogo, secondaryLogo, name, countryId, city } = organization;
  const primaryColor = organization?.primaryColor || "#5664d2";
  const [selecetdCountry, setSelectedCountry] = useState("");
  const dateUtils = new DateUtils();

  let logo = primaryLogo || secondaryLogo;

  const handleFetchLocations = async () => {
    try {
      const result = await axios.post(API_URL_FETCH_RISK_LOCATIONS, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      if (result.status === 200) {
        const selectedLocation = result.data.data.filter(
          (item) => Number(item.id) === Number(countryId)
        )[0].name;

        setSelectedCountry(selectedLocation);
      }
    } catch (error) {
      if (process?.env?.NODE_ENV === "development") {
        console.error(
          "this error only appears in the development environment:\nerror while fetching locations",
          error
        );
      }
    }
  };

  const generateColor = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor?.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

  useEffect(() => {
    if (countryId) {      handleFetchLocations();
    }
  }, [countryId]);

  const startColor = primaryColor;
  const stopColor = generateColor(primaryColor, 15);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <FirstHeader startColor={startColor} stopColor={stopColor} backgroundColor={primaryColor} logo={logo} />
        <View style={styles.section}>
          <FirstPageContent
            name={name}
            country={selecetdCountry}
            city={city}
            downloadedAt={dateUtils.getCurrentDate()}
            created={dateUtils.convertTimestampToOnlyDate(data.created_at)}
            updated={dateUtils.convertTimestampToOnlyDate(data.updated_at)}
          />
        </View>
        <FirstFooter backgroundColor={primaryColor} />
      </Page>

      <Page size="A4" style={styles.page}>
        <Header startColor={startColor} stopColor={stopColor} backgroundColor={primaryColor} logo={logo} />
        <View style={styles.section}>
          <PageContent
            sections={data?.sections || []}
            sub_contractors={data?.sub_contractors || []}
          />
        </View>
        <FirstFooter backgroundColor={primaryColor} />
      </Page>
    </Document>
  );
};

export default PdfDocument;
