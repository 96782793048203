import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";

import paginationFactory, {
  PaginationProvider, 
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone
} from "react-bootstrap-table2-paginator";

import { toast } from "react-toastify";

// import SupplierHistoryLogService from "../../../../../../apis/SupplierHistoryLogService";

import DateUtils from "src/services/utils/DateUtils";

import { EMPTY_LIST, INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

import { Button, Col, Row } from "reactstrap";

import {
  HistoryLogActions,
  HistoryLogSections,
  HistoryLogRoles,
} from "../../../../constants/Common";

import TableFilter from "./components/table-filter";

import DetailsModal from "./components/details-modal";
import axios from "axios";

import { API_BASE_URL } from "../../../../constants/index";

const HistoryLog = ({ t, token, supplierId, historyUrl, isDownloadButton, isSearchAvailable }) => {
  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    fromDate: null,
    toDate: null,
    user: null,
    sections: [],
    actions:[]
  });

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);

  const [logs, setLogs] = useState([]);

  const [selectedLog, setSelectedLog] = useState(null);

  const dateUtils = new DateUtils();

  let supplierData = {}

  if(!supplierId){
    supplierData = {
        dataField: "supplier",
        text: t("Supplier"),
        sort: false,
        key: 2,
        style: {
            width: 180,
        },
        formatter: (cellContent, row) => {
            return row.supplier?.name;
        }
    }
  }

  const tableColumns = [
    {
      dataField: "secondary_id",
      text: "#",
      sort: false,
      key: 1,
      style: {
        width: 120,
      },
      formatter : (cellContent, row) => {
        return !supplierId ? `T${row.supplier?.id}-${row.secondary_id}` : row.secondary_id; 
      }
    },
    {
      dataField: "action",
      text: t("Activity"),
      sort: false,
      key: 2,
      style: {
        width: 180,
      },
      formatter: (cellContent, row) => {
        return t(HistoryLogSections[row.section]);
      },
    },
    {...supplierData},
    {
      dataField: "date_time",
      text: t("Date and time"),
      sort: false,
      key: 3,
      style: {
        width: 180,
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            <span>
              {dateUtils.convertDateToDate(
                row.created_at,
                INTERNATIONAL_DATE_FORMAT
              )}
            </span>
            <br />
            <span className="text-muted">
              {dateUtils.convertDateToDate(row.created_at, "HH:mm")}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "user_name",
      text: t("User"),
      sort: false,
      key: 4,
      style: {
        width: 180,
      },
    },
    {
      dataField: "user_role",
      text: t("User Role"),
      sort: false,
      key: 5,
      style: {
        width: 180,
      },
      formatter: (cellContent, row) => {
        return HistoryLogRoles[row.user_role];
      },
    },
    {
      dataField: "description",
      text: t("Description"),
      sort: false,
      key: 6,
      formatter: (cellContent, row) => {
        const section  = HistoryLogActions[row.section];

        return t(section ? section[row.action] : '');
      },
    },
    {
      dataField: "actions",
      text: t("Action"),
      sort: false,
      key: 7,
      formatter: (cellContent, row) => {
        return (
          <div className="actions">
            <Button
              color="primary"
              onClick={() => setSelectedLog(row)}
              outline
              className="border-0">
              <i className="ri-eye-line"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const NoDataIndication = () =>
    handleFetchSupplierHistoryLogQuery.isFetched &&
    !handleFetchSupplierHistoryLogQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const handleFetchSupplierHistoryLogQuery = useQuery(
    ["supplierHistoryLogs", historyUrl, tableFilters],
    async () => {

     // Create a copy of tableFilters
      const params = { ...tableFilters };
      //the Axios is automatically serializing the user array parameter in a format that includes indices within brackets.
      
      // Serialize user array parameter if it's not empty
      if (Array.isArray(tableFilters.user) && tableFilters.user.length > 0) {
        params.user = `[${tableFilters.user.join(",")}]`;
      }

      // Serialize roles array parameter if it's not empty
      if (Array.isArray(tableFilters.actions) && tableFilters.actions.length > 0) {
        params.actions = `[${tableFilters.actions.join(",")}]`;
      }

      // Serialize sections array parameter if it's not empty
      if (
        Array.isArray(tableFilters.sections) &&
        tableFilters.sections.length > 0
      ) {
        params.sections = `[${tableFilters.sections.join(",")}]`;
      }

      const response = await axios.get(`${API_BASE_URL}${historyUrl}`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data?.data || [];
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast(t("An error occurred while fetching supplier history logs."), {
          type: "error",
        });
      },
    }
  );

  const handleTableChange = (type, { page, sizePerPage }) => {
    switch (type) {
      case "pagination":
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;
      default:
        return false;
    }
  };

  /**
   * use this if you want to filter table on each filter change event
   */
  const onTableFilterChanged = (type, filters) => {
    switch (type) {
      case "fromDate":
      case "toDate":
        setTableFilters({
          ...tableFilters,
          [type]: dateUtils.convertDateToDate(
            new Date(filters[type]),
            "YYYY-MM-DD HH:mm:ss"
          ),
        });
        break;

      case "user":
      case "roles":
      case "sections":
        setTableFilters({
          ...tableFilters,
          [type]: filters[type],
        });
        break;

      default:
        return null;
    }
  };

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};

    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        if (tableFilterKey === "fromDate") {
            result[tableFilterKey] = filters['fromDate'] !== null
            ? dateUtils.convertDateToDate(
                  new Date(filters['fromDate']),
                  "YYYY-MM-DD HH:mm:ss"
              )
            : null;
        }
        else if(tableFilterKey === "toDate") {
            result[tableFilterKey] = filters['toDate'] !== null
            ? dateUtils.convertDateToDate(
                  new Date(filters['toDate']),
                  "YYYY-MM-DD"
              ) + ' 23:59:59'
            : null;
        }
        else {
          result[[tableFilterKey]] = filters[tableFilterKey];
        }
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
      }
    }
    setTableFilters(result);
  };

  useEffect(() => {
    if (handleFetchSupplierHistoryLogQuery.data) {
      setLogs(handleFetchSupplierHistoryLogQuery.data.logs || []);
      setTableResultTotalCount(
        handleFetchSupplierHistoryLogQuery.data.itemsCount || 0
      );
    }
  }, [handleFetchSupplierHistoryLogQuery.data]);

  const handleDownloadData = async () => {
        // Create a copy of tableFilters
        const params = { ...tableFilters };
        //the Axios is automatically serializing the user array parameter in a format that includes indices within brackets.
        
        // Serialize user array parameter if it's not empty
        if (Array.isArray(tableFilters.user) && tableFilters.user.length > 0) {
          params.user = `[${tableFilters.user.join(",")}]`;
        }
  
        // Serialize roles array parameter if it's not empty
        if (Array.isArray(tableFilters.actions) && tableFilters.actions.length > 0) {
          params.actions = `[${tableFilters.actions.join(",")}]`;
        }
  
        // Serialize sections array parameter if it's not empty
        if (
          Array.isArray(tableFilters.sections) &&
          tableFilters.sections.length > 0
        ) {
          params.sections = `[${tableFilters.sections.join(",")}]`;
        }

        axios
        .get(`${API_BASE_URL}/logs/download`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params, // Include params object here
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          const link = document.createElement("a");
      
          link.href = url;
          link.setAttribute("download", `history-logs-${Date.now()}.xlsx`);
      
          document.body.appendChild(link);
      
          link.click();
          link.remove();
        })
        .catch(() => {
          toast(t("An error occurred while downloading the file."), {
            type: "error",
          });
        });

      }
      
  return (
    <Row className="p-4">
      <Col sm="12">
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: tableFilters.pageIndex,
            sizePerPage: tableFilters.pageSize,
            totalSize: tableResultTotalCount,
            withFirstAndLast: false,
            alwaysShowAllBtns: true,
            prePageText: (
              <span>
                <i className="ri-arrow-left-s-line"></i> {t("Back")}
              </span>
            ),
            nextPageText: (
              <span>
                {t("Next")} <i className="ri-arrow-right-s-line"></i>
              </span>
            ),
            prePageTitle: t("Pre page"),
            firstPageTitle: t("Next page"),
            showTotal           :   false,
            paginationTotalRenderer :   (from, to, size) => {
              return (
                <span className="react-bootstrap-table-pagination-total">
                    {`${t('Total Items')} ${size}`}
                </span>
              )
            },
            hideSizePerPage: false,
            sizePerPageList: [
              {
                text: "25",
                value: 25,
              },
              {
                text: "50",
                value: 50,
              },
            ],
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <React.Fragment>
              <Row className="mb-3 mt-3">
                <Col sm="12">
                  <div className="d-flex gap-4 justify-content-end">
                    <TableFilter
                      supplierId={supplierId}
                      defaultValues={{
                        ...tableFilters,
                      }}
                      // onFilterChanged={ onTableFilterChanged }
                      onOkButtonClicked={onTableFilterOkButtonClicked}
                      isSearchAvailable={isSearchAvailable}
                    />
                    {isDownloadButton && (
                      <Button
                        color="primary"
                        onClick={() => handleDownloadData()} //handleDownloadData()
                      >
                        {t("Download Data")}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <BootstrapTable
                    remote={{
                      pagination: true,
                      filter: false,
                      sort: true,
                      cellEdit: false,
                      search: false,
                    }}
                    loading={
                      handleFetchSupplierHistoryLogQuery.isFetching ||
                      handleFetchSupplierHistoryLogQuery.isLoading
                    }
                    overlay={overlayFactory({
                      spinner: (
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="md"
                        />
                      ),
                      text: "Loading...",
                    })}
                    onTableChange={handleTableChange}
                    defaultSorted={[]}
                    keyField={"id"}
                    responsive
                    bordered={false}
                    data={logs}
                    striped={false}
                    columns={tableColumns}
                    wrapperClasses="table-responsive"
                    classes={"table tpdd-table"}
                    headerWrapperClasses={"thead-light"}
                    style={{
                      overflowX: "auto",
                    }}
                    noDataIndication={() => <NoDataIndication />}
                    {...paginationTableProps}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <div className="tpdd-pagination-style-1">
                    <PaginationTotalStandalone
                      { ...paginationProps }
                    />

                    <div>
                      <PaginationListStandalone {...paginationProps} />

                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </PaginationProvider>

        {selectedLog && (
          <DetailsModal
            supplierId={supplierId}
            logId={selectedLog.id}
            onClose={() => setSelectedLog(null)}
          />
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const { token } = state.Login;

  return {
    token,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStateToProps)(memo(HistoryLog)))
);
