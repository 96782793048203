import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import DateUtils from '../../../../../../services/utils/DateUtils';
import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML } from 'draft-js';
import { TbLoader } from 'react-icons/tb';
import { Button, Card, CardHeader, Col, Container, Input, InputGroup, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
	DESCRIPTION_IS_REQUIRED,
	UPDATE_CATEGORY_SUCCESSFUL,
	UPDATE_CATEGORY_FAILED,
	UPDATE_DESCRIPTION_SUCCESSFUL,
	UPDATE_DESCRIPTION_FAILED,
	UPDATE_OWNER_SUCCESSFUL,
	UPDATE_OWNER_FAILED,
	UPDATE_STATUS_SUCCESSFUL,
	UPDATE_STATUS_FAILED,
	UPDATE_PRIORITY_SUCCESSFUL,
	UPDATE_PRIORITY_FAILED,
	UPDATE_DEADLINE_SUCCESSFUL,
	UPDATE_DEADLINE_FAILED,
	FETCH_TASK_FAILED,
	INTERNATIONAL_DATE_FORMAT,
	INVALID_DEADLINE_DATE,
	FETCH_COMMENTS_FAILED,
	CATEGORY_IS_REQUIRED,
} from '../../../../../../common/constants';
import {
    API_BASE_URL,
    API_URL_TASK_DETAILS,
    API_URL_TASK_GET_COMMENTS
} from '../../../../constants'
import Select from 'react-select';
import DownloadAttachments from '../../CommonForTaskAndRisk/DownloadAttachments';
import MemorizedCommentsComponent from '../CommonComponents/Comments';
import { IoIosArrowBack } from 'react-icons/io';
import { formatTaskAndRiskId } from "src/modules/data-protection/constants/Common"

const TaskDetails = function (props) {
	TaskDetails.displayName = 'Memorized Component - Task Details';
	if (process.env.NODE_ENV === 'development') console.log('---- rendering memorized component >>> TaskDetails');

	const dateUtils = useMemo(() => {
		return new DateUtils();
	}, []);
	const {
		t,
		successNotification,
		errorNotification,
		categories,
		owners,
		priorities,
		taskId,
		authToken,
		taskStatuses,
		deleteTask,
		currentUser,
		back,
		module,
		isTaskOwnerEmployee,
	} = props;
	const [task, setTask] = useState(null);
	const [comments, setComments] = useState(null);

	const [selectedCategory, setSelectedCategory] = useState(null);
	const [categoryOtherError, setCategoryOtherError] = useState(null);
	const [selectedSubCategoryOtherString, setSelectedSubCategoryOtherString] = useState('');
	// const [categoryError, setCategoryError] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [description, setDescription] = useState(EditorState.createEmpty());
	const [descriptionError, setDescriptionError] = useState(null);
	const [selectedOwner, setSelectedOwner] = useState(null);
	const [selectedPriority, setSelectedPriority] = useState(null);
	const [selectedDeadline, setSelectedDeadline] = useState('');

	const [loadingTask, setLoadingTask] = useState(null);
	const [loadingCategory, setLoadingCategory] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState(false);
	const [loadingDescription, setLoadingDescription] = useState(false);
	const [loadingOwner, setLoadingOwner] = useState(false);
	const [loadingPriority, setLoadingPriority] = useState(false);
	const [loadingDeadline, setLoadingDeadline] = useState(false);
	const [loadingComments, setLoadingComments] = useState(false);

	/**
	 * this method fetches a task.
	 * @param {String} taskId the ID of the task you want to fetch.
	 * @param {String} token authorizatio token.
	 */
	const handleFetchTask = useCallback(
		async (taskId, token) => {
			setLoadingTask(true);
			try {
				const result = await axios.get(`${API_URL_TASK_DETAILS}/task/${taskId}/show`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (result.status === 200) {
					if (result.data.data.report_case_id !== localStorage.getItem('caseId')) {
						localStorage.setItem('caseId', result.data.data.report_case_id);
					}
					setTask(result.data.data);
				} else {
					errorNotification({
						message: t(FETCH_TASK_FAILED),
					});
				}
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while fetching task', error);
				}

				errorNotification({
					message: t(FETCH_TASK_FAILED),
				});

				back();
			}
			setLoadingTask(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[errorNotification],
	);

	/**
	 * this method submits the change category request to the server.
	 * @param {String} taskId the ID of the task you want to change category for.
	 * @param {String} category the category string.
	 * @param {String} subcategory the sub category string.
	 * @param {String} token authorizatio token.
	 */
	const handleSubmitChangeCategory = useCallback(
		async (taskId, category, subCategory, token) => {
			if (!category && !subCategory) {
				setCategoryOtherError(t(CATEGORY_IS_REQUIRED));
				return;
			} else {
				setCategoryOtherError(null);
			}

			setLoadingCategory(true);
			const data = !subCategory ? { category: category } : { category: null, otherCategory: subCategory };

			try {
				const result = await axios.post(
					`${API_BASE_URL}/task/${taskId}/change_category`,
					data,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (result.status === 200) {
					successNotification({
						message: t(UPDATE_CATEGORY_SUCCESSFUL),
					});
				} else {
					errorNotification({
						message: t(UPDATE_CATEGORY_FAILED),
					});
				}
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while updating category', error);
				}

				errorNotification({
					message: t(UPDATE_CATEGORY_FAILED),
				});
			}
			setLoadingCategory(false);
		},
		[t, successNotification, errorNotification],
	);

	/**
	 * this method handles change category flow.
	 * @param {String} taskId the ID of the task you want to change category for.
	 * @param {Object} category the category object.
	 * @param {String} token authorization token.
	 */
	const handleChangeCategory = useCallback(
		(taskId, category, token) => {
			setSelectedCategory(category);

			if (t(category.label.toLowerCase()) !== t('Other'.toLowerCase())) {
				handleSubmitChangeCategory(taskId, category.value, null, token);
			}
		},
		[t, handleSubmitChangeCategory],
	);

	/**
	 * this method submits the change status request to the server.
	 * @param {String} taskId the ID of the task you want to change category for.
	 * @param {Object} status the selected status.
	 * @param {String} token authorizatio token.
	 */
	const handleChangeStatus = useCallback(
		async (taskId, status, token) => {
			const prevStatus = Object.create(selectedStatus);
			setLoadingStatus(true);

			if(selectedStatus?.value?.toLowerCase() !== "new" && status?.value?.toLowerCase() === "new") {
				errorNotification({
					message: t("You do not have permission to change the task status to 'New'."),
				});

				return setLoadingStatus(false);
			}

			setSelectedStatus(status);
			
			try {
				if (t(status.label).toLowerCase() === t('DELETED').toLowerCase()) {
					const result = await deleteTask(taskId, null, token);
					if (!result) setSelectedStatus(prevStatus);
				} else {
					const result = await axios.post(
						`${API_BASE_URL}/task/${taskId}/change_status`,
						{
							status: status.value,
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
					);

					if (result.status === 200) {
						successNotification({
							message: t(UPDATE_STATUS_SUCCESSFUL),
						});
					} else {
						setSelectedStatus(prevStatus);
						errorNotification({
							message: t(UPDATE_STATUS_FAILED),
						});
					}
				}
			} catch (error) {
				setSelectedStatus(prevStatus);

				if (process.env.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while updating status', error);
				}

				errorNotification({
					message: t(UPDATE_STATUS_FAILED),
				});
			}

			setLoadingStatus(false);
		},
		[deleteTask, successNotification, errorNotification, t, selectedStatus],
	);

	/**
	 * this method submits the change description request to the server.
	 * @param {String} taskId the ID of the task you want to change description for.
	 * @param {String} token authorizatio token.
	 */
	const handleDescriptionSubmit = async (taskId, token) => {
		try {
			if (!description.getCurrentContent().hasText()) {
				setDescriptionError(DESCRIPTION_IS_REQUIRED);
				return;
			} else {
				setDescriptionError(null);
			}

			setLoadingDescription(true);
			const result = await axios.post(
				`${API_BASE_URL}/task/${taskId}/update`,
				{
					description: convertToHTML(description.getCurrentContent()),
					descriptionAttachments: [],
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				successNotification({
					message: t(UPDATE_DESCRIPTION_SUCCESSFUL),
				});
			} else {
				errorNotification({
					message: t(UPDATE_DESCRIPTION_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while updating description', error);
			}

			errorNotification({
				message: t(UPDATE_DESCRIPTION_FAILED),
			});
		}

		setLoadingDescription(false);
	};

	/**
	 * this method submits the change owner request to the server.
	 * @param {String} taskId the ID of the task you want to change owner for.
	 * @param {Object} owner the selected owner.
	 * @param {String} token authorization token.
	 */
	const handleChangeOwner = async (taskId, owner, token) => {
		try {
			setLoadingOwner(true);
			setSelectedOwner(owner);
			const result = await axios.post(
				`${API_BASE_URL}/task/${taskId}/assign_analyst`,
				{ analyst: owner.value },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				props.successNotification({
					message: t(UPDATE_OWNER_SUCCESSFUL),
				});
			} else {
				errorNotification({
					message: t(UPDATE_OWNER_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while updating owner', error);
			}

			errorNotification({
				message: t(UPDATE_OWNER_FAILED),
			});
		}

		setLoadingOwner(false);
	};

	/**
	 * this method submits the change priority request to the server.
	 * @param {String} taskId the ID of the task you want to change priority for.
	 * @param {Object} priority the selected priority.
	 * @param {String} token authorization token.
	 */
	const handleChangePriority = async (taskId, priority, token) => {
		setSelectedPriority(priority);
		setLoadingPriority(true);
		try {
			const result = await axios.post(
				`${API_BASE_URL}/task/${taskId}/change_priority`,
				{
					priority: priority.value,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				successNotification({
					message: t(UPDATE_PRIORITY_SUCCESSFUL),
				});
			} else {
				errorNotification({
					message: t(UPDATE_PRIORITY_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while updating priority', error);
			}

			errorNotification({
				message: t(UPDATE_PRIORITY_FAILED),
			});
		}

		setLoadingPriority(false);
	};

	/**
	 * this method submits the change deadline request to the server.
	 * @param {String} taskId the ID of the task you want to change request for.
	 * @param {Object} priority the selected request.
	 * @param {String} token authorization token.
	 */
	const handleChangeDeadline = async (taskId, deadline, token) => {
		try {
			let date = new Date();
			date = date.toISOString().split('T')[0];
			if (Number(deadline.replaceAll('-', '')) < Number(date.replaceAll('-', ''))) {
				errorNotification({
					message: t(INVALID_DEADLINE_DATE),
				});

				return false;
			}

			setLoadingDeadline(true);

			const result = await axios.post(
				`${API_BASE_URL}/task/${taskId}/change_deadline`,
				{ endedAt: deadline },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (result.status === 200) {
				successNotification({
					message: t(UPDATE_DEADLINE_SUCCESSFUL),
				});
			} else {
				errorNotification({
					message: t(UPDATE_DEADLINE_FAILED),
				});
			}
		} catch (error) {
			if (process.env.NODE_ENV === 'development') {
				console.error('this error only appears in the development environment:\nerror while updating deadline', error);
			}

			errorNotification({
				message: t(UPDATE_DEADLINE_FAILED),
			});
		}

		setLoadingDeadline(false);
	};

	/**
	 * this method fetches comments of a task.
	 * @param {String} taskId the ID of the task you want to change request for.
	 * @param {String} token authorization token.
	 */
	const handleFetchComments = useCallback(
		async (taskId, token) => {
			setComments(null);
			setLoadingComments(true);
			try {
				const result = await axios.get(
					 `${API_URL_TASK_GET_COMMENTS}/task/${taskId}/comments`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				);

				if (result.status === 200) {
					setComments(result.data.data);
				} else {
					errorNotification({
						message: t(FETCH_COMMENTS_FAILED),
					});
				}
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.error('this error only appears in the development environment:\nerror while fetching comments', error);
				}

				errorNotification({
					message: t(FETCH_COMMENTS_FAILED),
				});
			}
			setLoadingComments(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[errorNotification],
	);

	const handleUpdateAttachmentName = (name, id) => {
		const index = task.description_attachments.findIndex((i) => i.id === id);

		if (index > -1) {
			const description_attachments = Array.from(task.description_attachments);
			description_attachments[index].origin_name = name;

			setTask({
				...task,
				description_attachments,
			});
		}
	};

	useEffect(() => {
		if (taskId) {
			setTask(null);
			handleFetchTask(taskId, authToken);
			setComments(null);
			handleFetchComments(taskId, authToken);
		}
	}, [taskId, authToken, handleFetchTask, handleFetchComments]);

	useEffect(() => {
		if (task && categories) {
			if (task.category?.is_other_category) {
				const category = categories.find((c) => c.baseLabel === 'Other');
				setSelectedCategory(category);
				setSelectedSubCategoryOtherString(task.category.name);
			} else {
				const category = categories.find((c) => c.value === task.category?.id);
				setSelectedCategory(category);
				setSelectedSubCategoryOtherString('');
			}
		}
	}, [task, categories]);

	useEffect(() => {
		if (task && taskStatuses) {
			const status = taskStatuses.find((s) => s.value === task.status);
			setSelectedStatus(status);
		}
	}, [task, taskStatuses]);

	useEffect(() => {
		if (task) {
			const blocksFromHTML = task.description ? convertFromHTML(task.description) : '';
			const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

			setDescription(EditorState.createWithContent(state));
		}
	}, [task]);

	useEffect(() => {
		if (task && owners) {
			const owner = owners.find((o) => o.label === task.owner);
			setSelectedOwner(owner);
		}
	}, [task, owners]);

	useEffect(() => {
		if (task && priorities) {
			const priority = priorities.find((o) => o.value === task.priority);
			setSelectedPriority(priority);
		}
	}, [task, priorities]);

	useEffect(() => {
		if (task) {
			const deadline = dateUtils.convertTimestampToOnlyDate(task.ended_at).toString().split('/').reverse().join('-');
			setSelectedDeadline(deadline);
		}
	}, [task, priorities, dateUtils]);

	useEffect(() => {
		if (selectedCategory) {
			setSelectedCategory({
				value: selectedCategory.value,
				baseLabel: selectedCategory.baseLabel,
				label: t(selectedCategory.baseLabel),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);


	
	return (
		<>
			<Row className='pt-2 ps-3'>
				<Col lg='2' md='2' sm='12'>
					{task ? (
						<>
							{/* arrow */}
							{!isTaskOwnerEmployee ? (
								<Fragment>
									<span
										style={{ cursor: 'pointer' }}
										color='bg-secondary'
										onClick={() => {
											back();
										}}
									>
										<span
											className='h3'
											onClick={() => {
												back();
											}}
										>
											<IoIosArrowBack />
										</span>
									</span>

									<span
										style={{
											display: 'inline-block',
											position: 'relative',
											top: '-0.1rem',
											cursor: 'pointer',
										}}
										onClick={() => {
											back();
										}}
									>
									
										{formatTaskAndRiskId('task',task?.questionnaire === "software" ? task?.asset_id : task?.case_secondary_id , task.secondary_id, task?.is_mitigation, task?.questionnaire, task.secondary_id )}
										
									</span>
								</Fragment>
							) : (
								<Fragment>
									<Link to={'/employee/tasks'} style={{ cursor: 'pointer' }} color='bg-secondary'>
										<span className='h3'>
											<IoIosArrowBack />
										</span>
									</Link>

									<Link to={'/employee/tasks'}
										style={{
											display: 'inline-block',
											position: 'relative',
											top: '-0.1rem',
											cursor: 'pointer',
										}}
									>
										{`${task.report_secondary_id}-T${task.secondary_id}`}
									</Link>
								</Fragment>
							)}
						</>
					) : (
						<span style={{ height: 30, display: 'block' }}></span>
					)}
				</Col>
			</Row>

			<div style={{ height: '1rem' }}></div>

			<Container>
				<Row>
					<Col lg='8' md='8' sm='12'>
						<Row>
							{/* title */}
							<Col sm='12' md='6' lg='3'>
								<Label className='form-label'>{`${t('Task')} `}</Label>
								<br />
								{!loadingTask && task ? (
									<Label className='form-label text-secondary '>{task.name}</Label>
								) : (
									<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
								)}
							</Col>

							{/* category */}
							<Col
								sm='12'
								md={selectedCategory && selectedCategory.baseLabel === 'Other' ? 3 : 4}
								lg={selectedCategory && selectedCategory.baseLabel === 'Other' ? 3 : 4}
							>
								<Label className='form-label'>{`${t('Category')} `}</Label>
								<br />

								{!loadingCategory && !loadingTask && categories && task ? (
									<Select
										name='category'
										classNamePrefix='select2-selection'
										options={categories.map((c) => {
											return {
												value: c.value,
												baseLabel: c.baseLabel,
												label: t(c.baseLabel),
											};
										})}
										value={selectedCategory && {...selectedCategory, label: t(selectedCategory.baseLabel)}}
										onChange={(e) => {
											handleChangeCategory(taskId, e, authToken);
										}}
										menuPortalTarget={document.body}
										isDisabled={isTaskOwnerEmployee || task.status==="CLOSED" ? true : false}
									/>
								) : (
									<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
								)}
							</Col>

							{/* subcategory */}
							<Col sm='12' md='3' lg='3' hidden={!selectedCategory || selectedCategory.baseLabel !== 'Other'}>
								<Label className='form-label text-dark'>{`${t('Subcategory')} `}</Label>
								<InputGroup>
									<Input
										name='sub-cat'
										type='text'
										className='form-control'
										value={selectedSubCategoryOtherString}
										validate={{ required: { value: true } }}
										onChange={(e) => {
											setSelectedSubCategoryOtherString(e.target.value);
										}}
										disabled={isTaskOwnerEmployee ? true : false}
									/>
									{!isTaskOwnerEmployee ? (
										<Button
											color='primary'
											onClick={(e) => {
												handleSubmitChangeCategory(taskId, null, selectedSubCategoryOtherString, authToken);
											}}
										>
											{t('Save')}
										</Button>
									) : null}
								</InputGroup>
								<br />
								<p className='text-danger'>{categoryOtherError ? t(categoryOtherError) : ''}</p>
							</Col>

							{/* status */}
							<Col
								sm='12'
								md={selectedCategory && t(selectedCategory.label.toLowerCase()) === t('Other'.toLowerCase()) ? 3 : 4}
								lg={selectedCategory && t(selectedCategory.label.toLowerCase()) === t('Other'.toLowerCase()) ? 3 : 4}
							>
								<Label className='form-label'>{`${t('Status')} `}</Label>
								<br />
								{!loadingStatus && !loadingTask && taskStatuses && task ? (
									<Select
										required
										name='status'
										classNamePrefix='select2-selection'
										options={(isTaskOwnerEmployee ? taskStatuses.filter((s) => s.taskOwner) : taskStatuses).map((s) => {
                                            return {
                                                ...s,
                                                label: t(s?.baseLabel || s?.label)
                                            }
                                        })}
										value={selectedStatus && {...selectedStatus, label: t(selectedStatus.baseLabel) || t(selectedStatus.label)}}
										onChange={(status) => {
											handleChangeStatus(taskId, status, authToken);
										}}
										menuPortalTarget={document.body}
										isDisabled={task?.status === "CLOSED"}
									></Select>
								) : (
									<div className='dt-field dt-skeleton dt-select-list' style={{ marginBottom: 16 }}></div>
								)}
							</Col>
						</Row>

						<Row className='mt-5'>
							{/* description */}
							<Col sm='12' md='12' lg='12'>
								<Label className='form-label'>{`${t('Description')} `}</Label>
								{task && task.description ? (
									isTaskOwnerEmployee ? (
										<p dangerouslySetInnerHTML={{ __html: task.description }}></p>
									) : (
										<Fragment>
											<br />
											<Editor
												editorState={description}
												toolbarClassName='toolbarClassName'
												wrapperClassName='wrapperClassName'
												editorClassName='editorClassName'
												onEditorStateChange={(e) => {
													setDescription(e);
												}}
												placeholder={t('Add a description...')}
												editorStyle={{
													height: '10rem',
													overflowY: 'scroll',
													borderColor: '#c9c9c9',
													paddingRight: '2em',
												}}
												toolbar={{
													options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
													inline: {
														options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
														bold: { className: 'bordered-option-classname' },
														italic: { className: 'bordered-option-classname' },
														underline: { className: 'bordered-option-classname' },
														strikethrough: {
															className: 'bordered-option-classname',
														},
														code: { className: 'bordered-option-classname' },
													},
													blockType: {
														className: 'bordered-option-classname',
													},
													fontSize: {
														className: 'bordered-option-classname',
													},
													fontFamily: {
														className: 'bordered-option-classname',
													},
												}}
											/>
											<br />
											<p className='text-danger'>{descriptionError ? t(descriptionError) : ''}</p>
										</Fragment>
									)
								) : null}
							</Col>
						</Row>

						<br />

						<Row>
							{/* submit description */}
							{task && task.description && !isTaskOwnerEmployee ? (
								<Col lg='2' md='2' sm='6' className=''>
									<Button className='bg-primary' style={{ display: 'block', width: '100%' }} onClick={(e) => handleDescriptionSubmit(taskId, authToken)}>
										{loadingDescription ? <TbLoader /> : t('Save')}
									</Button>
								</Col>
							) : null}

							{/* download attachments */}
							{task && task.description_attachments && task.description_attachments.length > 0 ? (
								<Col lg='10' md='10' sm='6' hidden={!(task && task.description_attachments && task.description_attachments.length > 0)}>
									<DownloadAttachments
										type={'task'}
										style={{ display: 'block', width: '100%' }}
										showAttachments={false}
										t={t}
										files={task.description_attachments}
										module={module}
									/>
								</Col>
							) : null}
						</Row>
					</Col>

					<Col lg='4' md='4' sm='12'>
						<Card
							outline
							body
							className='border border-primary rounded'
							style={{
								width: 'auto',
							}}
						>
							<CardHeader>{t('Details')}</CardHeader>
							<br></br>
							<ListGroup flush>
								{/* manager */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p>{t('Task Manager')}</p>
										</Col>
										<Col>
											{task ? (
												<span className='badge bg-success text-white rounded p-2' style={{ marginBottom: 0, display: 'block' }}>
													{task.manager}
												</span>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* owners */}
								<ListGroupItem className='border-bottom border-primary '>
									<Row>
										<Col>
											<p>{t('Task owner')}</p>
										</Col>
										<Col>
											{task ? (
												isTaskOwnerEmployee ? (
													<span className='badge bg-success text-white rounded p-2' style={{ marginBottom: 0, display: 'block' }}>
														{t('Yourself')}
													</span>
												) : !loadingOwner && owners ? (
													<Select
														required
														name='owner'
														classNamePrefix='select2-selection'
														options={owners}
														isDisabled={`${currentUser.first_name} ${currentUser.last_name}` !== task.manager}
														value={selectedOwner}
														onChange={(e) => {
															handleChangeOwner(taskId, e, authToken);
														}}
													/>
												) : (
													<div className='dt-field dt-skeleton dt-select-list'></div>
												)
											) : (
												<div className='dt-field dt-skeleton dt-select-list'></div>
											)}

											{/* {!loadingOwner && owners && task ? (
												<Select
													required
													name='owner'
													classNamePrefix='select2-selection'
													options={owners}
													isDisabled={`${currentUser.first_name} ${currentUser.last_name}` !== task.manager}
													value={selectedOwner}
													onChange={(e) => {
														handleChangeOwner(taskId, e, authToken);
													}}
												/>
											) : (
												<div className='dt-field dt-skeleton dt-select-list'></div>
											)} */}
										</Col>
									</Row>
								</ListGroupItem>

								{/* priority */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p>{t('Priority')}</p>
										</Col>
										<Col>
											{!loadingPriority && priorities && task ? (
												<Select
													required
													name='priority'
													classNamePrefix='select2-selection'
													options={priorities.map((p) => {
                                                        return {...p, label: t(p.baseLabel)}
                                                    })}
													value={selectedPriority && {...selectedPriority, label: t(selectedPriority.baseLabel)}}
													onChange={(e) => {
														handleChangePriority(taskId, e, authToken);
													}}
													isDisabled={isTaskOwnerEmployee ? true : false}
												/>
											) : (
												<div className='dt-field dt-skeleton dt-select-list'></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* created at */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p>{t('Created')}</p>
										</Col>
										<Col>
											{task ? (
												<Label style={{ marginBottom: 0, lineHeight: '26px' }}>
													{dateUtils.convertTimeStampToDate(task.created_at, INTERNATIONAL_DATE_FORMAT)}
												</Label>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* started at */}
								<ListGroupItem className='border-bottom border-primary'>
									<Row>
										<Col>
											<p>{t('Start Date')}</p>
										</Col>
										<Col>
											{task ? (
												<Label style={{ marginBottom: 0, lineHeight: '26px' }}>
													{dateUtils.convertTimeStampToDate(task.started_at, INTERNATIONAL_DATE_FORMAT)}
												</Label>
											) : (
												<div className='dt-field dt-skeleton' style={{ height: 27 }}></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>

								{/* deadline */}
								<ListGroupItem>
									<Row>
										<Col>
											<p>{t('Deadline')}</p>
										</Col>
										<Col>
											{!loadingDeadline && task ? (
												<Input
													name='deadlineDate'
													min={dateUtils.convertTimestampToOnlyDate(task.started_at).toString().split('/').reverse().join('-')}
													type='date'
													value={selectedDeadline}
													onChange={(e) => handleChangeDeadline(taskId, e.target.value, authToken)}
													disabled={isTaskOwnerEmployee ? true : false}
												/>
											) : (
												<div className='dt-field dt-skeleton dt-select-list'></div>
											)}
										</Col>
									</Row>
								</ListGroupItem>
							</ListGroup>
						</Card>
					</Col>
				</Row>

				<MemorizedCommentsComponent
					useCase={'task'}
					t={t}
					authToken={authToken}
					successNotification={successNotification}
					errorNotification={errorNotification}
					id={taskId}
					owner={task?.owner}
					manager={task?.manager}
					descriptionAttachments={task?.description_attachments}
					successDeletion={(fileId) => {
						setTask({
							...task,
							description_attachments: task.description_attachments.filter((f) => f.id !== fileId),
						});
					}}
					creationDate={task ? dateUtils.convertTimestamp(task.created_at) : null}
					comments={comments}
					fetchComments={() => handleFetchComments(taskId, authToken)}
					loadingComments={loadingComments}
					updateAttachmentName={(name, id) => {
						handleUpdateAttachmentName(name, id);
					}}
					module={module}
					isReadOnly={task?.status==="CLOSED" ? true : false} 
				/>
			</Container>
		</>
	);
};

export default memo(TaskDetails);
