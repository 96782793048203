import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { withNamespaces } from "react-i18next";
import UserUtils from "../../services/utils/UserUtils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const availableList = [
    'wb',
    'cm'
]

const Footer = (props) => {
  const userUtils = new UserUtils();
  const isAdminOrAnalyst = userUtils.isAnalystOrAnalystAdmin(props.user);
  const isEmployee = userUtils.isEmployee(props.user);
  const isTP = userUtils.isThirdParty(props.user);
  const modules = props.modules;
  const route = isAdminOrAnalyst
    ? "/admin/"
    : isEmployee
    ? "/employee/"
    : "/third-party/";
  return (
    <div style={{marginTop:'4rem'}}>
      <footer className="footer" style={{height:'fit-content'}}>
        <Container fluid>
          <Row>
            <Col md={8}>
              {props.t(
                "© 2024 SMART INTEGRITY PLATFORM.  All rights reserved by DISS-CO ®."
              )}
              {modules?.active && availableList.includes(modules.active) && (
                <>
                    <Link to={`${route}user_policy`} className="m-3">
                        {props.t("User policy")}
                    </Link>
                    <Link to={`${route}data_privacy`}>{props.t("Data privacy")}</Link>
                </>
              )}
            </Col>

            <Col  md={4} className="text-sm-left text-md-end">
              {`${props.t("Version")}: ${process.env.REACT_APP_SIP_VERSION}`}
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

const mapStatetoProps = (state) => {
    return { modules: state.Modules };
};

export default connect(mapStatetoProps, {})(withNamespaces()(Footer));
