import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, { 
    PaginationProvider, 
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';

import FlagOnIcon from "../../../../assets/images/flag_on.svg";
import FlagOffIcon from "../../../../assets/images/flag_off.svg";

import ThirdpartyUsersService from "../../../../apis/ThirdpartyUsersService";

import CommonService from "src/modules/3rd-party-management/apis/CommonService";

import SupplierService from "../../../../apis/SupplierService";

import SupplierExportService from "src/modules/3rd-party-management/apis/SupplierExportService";

import { EMPTY_LIST } from "../../../../../../common/constants";

import CreateModal from '../../suppliers/new/components/AIHelpModal';

import { toast } from "react-toastify";

import Spinner from 'react-bootstrap/Spinner';

import QRCode from "react-qr-code";

import { pdf } from "@react-pdf/renderer";

import SupplierDetailPDF from "../exports/Supplier.jsx";

import { saveAs } from "file-saver";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    ModalFooter
  } from "reactstrap";

import useUploadCSV from "src/modules/3rd-party-management/hooks/useUploadCSV";

import DateUtils from "src/services/utils/DateUtils";

import Score from "src/modules/3rd-party-management/components/score";

import {
    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import {
    API_BASE_URL,
    RisksRates
} from "src/modules/3rd-party-management/constants";

import EditSupplierModal from './components/EditSupplierModal';

import TableFilter from "src/modules/3rd-party-management/components/table-filter";

import {
    SupplierStatuses
} from "src/modules/3rd-party-management/constants";
import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";
import axios from "axios";

const List = (props) => {
    const [ tableFilters, setTableFilters ] = useState({
        pageIndex       :   1,
        pageSize        :   25,
        createdAtFrom   :   null,
        createdAtTo     :   null,
        legal           :   null,
        flag            :   null,
        supplierStatus  :   null,
        country         :   null,
        industries      :   [],
        products        :   []
    });

    const [ tableResultTotalCount, setTableResultTotalCount ] = useState(0);

    const [usersList, setUsersList] = useState([]);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isUploadingCSV, setIsUploadingCSV] = useState(false);
    const [showThirdpartyLinkModal, setShowThirdpartyLinkModal] = useState(false);
    const [selectedOrgLink, setSelectedOrgLink] = useState("");
    const [linkIsCopied, setLinkIsCopied] = useState(false);
    const [ supplierIDToRemoveFlag, setSupplierIDToRemoveFlag ] = useState(null);
    const [ selectedSupplierToEditId, setSelectedSupplierToEditId ] = useState(null);
    const [ selectedSupplierToExport, setSelectedSupplierToExport ] = useState(null);
    const [ excelExportInprocess, setExcelExportInprocess ] = useState(false)

    const [ productsFilter, setProductsFilter ] = useState({
        pageSize: 25,
        pageIndex: 1,
        industries: [],
        productName: ''
    })

    const clipboardIsAvailable = navigator && navigator.clipboard;

    const dateUtils = new DateUtils();

    const {openUploadDialog, uploadCSV} = useUploadCSV({
        props       : props, 
        Service     : ThirdpartyUsersService, 
		onSuccess   : (data) => {
			toast(props.t('csv uploaded successfully'), {
				type: 'success',
			});
		},
		onError     : (error) => {
			const errors = []
			if (error?.response?.data) {
				const errorData = error.response.data.error;
				Object.keys(errorData).forEach((key, index) => {
  			        if(typeof errorData[key] === 'string') {
							errors.push(<li>{`${props.t("Line")} ${key}: ${errorData[key]}`}</li>)
		   		    }
					if(typeof errorData[key] === 'object') {
						Object.keys(errorData[key]).forEach(errorKey => {
							errors.push(<li>{`${props.t("Line")} ${key}: ${errorData[key][errorKey]}`}</li>)
						})
					}
				})
			}
            
			toast(<ul>{errors}</ul>, {
				type: 'error',
				style: {
				    width: 'max-content'
				}
			});
		},
        onStart     : () => {
            setIsUploadingCSV(true);
        },
        onSettled   : () => {
            setIsUploadingCSV(false);
        }
    });

    const {
        actionsRef
    } = props;

    const tableColumns = [
        {
            dataField :   "highestRisk",
            text      :   props.t("Risk Rating"),
            sort      :   false,
            key       :   1,
            style     :   {
                width : '100px'
            },
            formatter : (cellContent, row) => {
                return (
                    <i style={{
                        color: `${RisksRates[(cellContent || 'medium').toLowerCase()]?.color}`
                    }} className="ri-checkbox-blank-circle-fill font-size-20"></i>
                );
            }
        },
        {
            dataField :   "score",
            text      :   props.t("DISS-CO Score"),
            sort      :   false,
            key       :   2,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent, row) => {
                return (
                    <Score  
                        outOf={ 10 } 
                        value={ row.score } 
                        displayNumericValue={ true } 
                    />
                )
            }
        },
        {
            dataField :   "id",
            text      :   props.t("ID"),
            sort      :   false,
            key       :   3,
            style     :   {
                width : '80px'
            }
        },
        {
            dataField :   "name",
            text      :   props.t("Company"),
            sort      :   false,
            key       :   4,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent, row) => {
                return (
                    <Link className="text-dark" to={`suppliers/${row.id}/details?tab=overview`}>{ cellContent }</Link>
                );
            }
        },
        {
            dataField :   "country",
            text      :   props.t("Country"),
            sort      :   false,
            key       :   5,
            style     :   {
                width : '120px'
            },
            formatter : (cellContent) => {
                return (
                    <span>{props.t(cellContent)}</span>
                );
            }
        },
        {
            dataField :   "creation_date",
            text      :   props.t("Created on"),
            sort      :   false,
            key       :   6,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent, row) => {
                return (
                    <div>
                        <span>
                            { dateUtils.convertDateToDate(row.createdAt, INTERNATIONAL_DATE_FORMAT) }
                        </span><br/>
                        <span className="text-muted">
                            {props.t('Updated on')}: { dateUtils.convertDateToDate('2024-01-02', INTERNATIONAL_DATE_FORMAT) }
                        </span>
                    </div>
                )
            }
        },
        {
            dataField :   "contact_person",
            text      :   props.t("Contact Person"),
            sort      :   false,
            key       :   7,
            style     :   {
                width : '180px'
            },
            formatter : (cellContent, row) => {
                const persons = (row.responsiblePersons && Array.isArray(row.responsiblePersons)) ? row.responsiblePersons : [];

                return persons.map((person) => {
                    return person.name
                }).join(', ');
            }
        },
        {
            dataField :   "responsible_person",
            text      :   props.t("Assigned Analyst"),
            sort      :   false,
            key       :   8,
            style     :   {
                width : '120px'
            }
        },
        {
            dataField :   "status",
            text      :   props.t("Status"),
            sort      :   false,
            key       :   9,
            style     :   {
                width : '80px'
            },
            formatter : (cellContent) => {
                return renderUserStatus(cellContent);
            }
        },
        {
            dataField :   "flag",
            text      :   props.t('Flag'),
            sort      :   false,
            key       :   10,
            style     :   {
                width : '80px'
            },
            formatter : (cellContent, row) => {
                if(row.flag){
                    return (
                        <Button onClick={() => setSupplierIDToRemoveFlag(row)} size="sm" outline className="border-0 toggle-supplier-flag-btn">
                            {
                                !!row.isLoading ? (
                                    <Spinner animation="border" variant="secondary" size="sm"/>
                                ) : (
                                    <img alt="" src={ FlagOnIcon } />
                                )
                            }
                        </Button>
                    ); 
                }
                else{
                    return (
                        <Button onClick={() => handleToggleSupplierFlagMutation.mutate(row)} size="sm" outline className="border-0 toggle-supplier-flag-btn">
                            {
                                !!row.isLoading ? (
                                    <Spinner animation="border" variant="secondary" size="sm"/>
                                ) : (
                                    <img alt="" src={ FlagOffIcon } />
                                )
                            }
                        </Button>
                    ); 
                }
            }
        },
        
        {
            dataField :   "actions",
            text      :   props.t("Action"),
            sort      :   false,
            key       :   8,
            formatter : (cellContent, row) => {
                return renderUserActions(row);
            }
        }
    ];

    const NoDataIndication = () => (
        (handleFetchUsersQuery.isFetched && !usersList.length) ? <div className="alert m-0" role="alert">
            <p style={{
                    textAlign: "center",
                    marginBottom: 0
                }}>
                {props.t(EMPTY_LIST)}
            </p>
        </div> : <></>
    );

    const handleFetchUsersQuery = useQuery({
		queryKey: ['3rd-party-management-list-users', tableFilters.pageIndex, tableFilters.pageSize],
		queryFn: async () => {
			const service = ThirdpartyUsersService.getInstance();

            return await service.list(tableFilters);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(props.t('An error occurred while fetching third parties list.'), {
				type: 'error',
			});
		},
	});

    const {
        isFetching: countriesFetching,
        isLoading: countriesLoading,
        data: countriesData
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-coutries-list-query'],
		queryFn: async () => {
			return await (ThirdpartyUsersService.getInstance()).fetchCountries();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(props.t('An error occurred while fetching countries list.'), {
				type: 'error',
			});
		}
	});

    const {
        isFetching: productsFetching,
        isLoading: productsLoading,
        data: productsData,
        refetch: refetchIndustriesProducts
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-products-list-query'],
        queryFn: async () => {
            return await (IndustriesService.getInstance()).fetchProducts({
                selectedIndustryIds: productsFilter.industries,
                productName: productsFilter.productName,
                pageIndex: productsFilter.pageIndex,
                pageSize: productsFilter.pageSize
            });
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(props.t('An error occurred while fetching industries list.'), {
                type: 'error',
            });
        }
    });

    const {
        isFetching: industriesFetching,
        isLoading: industriesLoading,
        data: industriesData
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-industries-list-query'],
		queryFn: async () => {
			return await (IndustriesService.getInstance()).fetchList();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(props.t('An error occurred while fetching industries list.'), {
				type: 'error',
			});
		}
	});

    const {
        isFetching  :   supplierExportDataIsFetching,
        refetch     :   fetchSupplierExportData
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-export-data'],
		queryFn: async () => {
			const service = SupplierExportService.getInstance();

            return await service.fetchExportData(selectedSupplierToExport.id);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: (error) => {
			toast(props.t('An error occurred while fetching supplier data.'), {
				type: 'error',
			});
		},
        onSuccess: async (supplierExportData) => {
            const fileName = `supplier-${selectedSupplierToExport.id}.pdf`;

            const blob = await pdf( 
                <SupplierDetailPDF 
                    organization={props.organization} 
                    supplierData={supplierExportData}
                /> 
            ).toBlob();
        
            saveAs(blob, fileName);

            setSelectedSupplierToExport(null);
        }
	});

    const {
        data        :   legalEntitiesData,
        isFetching  :   legalEntitiesFetching,
        isLoading   :   legalEntitiesLoading
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-legal-entities-list'],
        queryFn: async () => {
            return await (CommonService.getInstance()).fetchLegalEntities();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(props.t('An error occurred while fetching entities.'), {
                type: 'error',
            });
        }
    });

    const handleToggleSupplierFlagMutation = useMutation({
        mutationFn: async (supplier) => {
            const service = SupplierService.getInstance();

            supplier.isLoading = true;

            return await service.toggleFlag(supplier.id);
        },
        onSuccess: ({
            flag    :   newFlag
        }, supplier) => {
            setSupplierIDToRemoveFlag(null);

            supplier.flag = newFlag;
            supplier.isLoading = false;

            toast(props.t("Supplier flag changed successfully"), {
                type: "success",
            });
        },
        onError: (data, supplier) => {
            supplier.isLoading = false;

            toast(props.t("An error occurred while toggling supplier flag"), {
                type: "error",
            });
        }
    });

    const copyLinkToClipboard = () => {
        if(clipboardIsAvailable){
            navigator.clipboard.writeText(selectedOrgLink).then(() => {
                toast(props.t('Link copied to clipboard'), {
                    type: 'success',
                });

                setLinkIsCopied(true);
                setTimeout(() => {
                    setLinkIsCopied(false);
                }, 3000);
                
            }).catch(() => {
                toast(props.t('Failed to copy link to clipboard'), {
                    type: 'error',
                });
            });
        }
    }

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}

            if ('filter-date' in filters) {
                result['createdAt[from]'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.from),
                    'YYYY-MM-DD'
                )

                result['createdAt[to]'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.to),
                    'YYYY-MM-DD'
                )
            }

            if ('country' in filters) {
                result.country = filters.country !== 'all' ? filters.country : undefined
            }

            if ('legal' in filters) {
                result.legal = filters.legal !== 'all' ? filters.legal : undefined
            }

            if ('supplierStatus' in filters) {
                result.supplierStatus = filters.supplierStatus !== 'all' ? filters.supplierStatus : undefined
            }

            if ('flag' in filters) {
                result.flag = filters.flag !== 'all' ? filters.flag : undefined
            }

            result.industries = filters.industries?.length > 0 ? filters.industries : undefined

            result.products = filters.products?.length > 0 ? filters.products : undefined
            
            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                pageIndex: tableFilters.pageIndex,
                pageSize: tableFilters.pageSize,
                createdAtFrom: null,
                createdAtTo: null,
                flag: null,
                supplierStatus: null,
                country: null,
                legal: null,
                industries: [],
                products:[]
            })
        }
    }

    const handleExportExcel = () => {
        setExcelExportInprocess(true)

        axios.get(`${API_BASE_URL}/organization/third_parties_excel`,
            {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
                onDownloadProgress: (e) => {
                    let percentCompleted = Math.round(e.loaded * 100 / 
                        e.total);

                    if(percentCompleted >= 100){
                        setExcelExportInprocess(false)
                    }
                },
                params: tableFilters
            }
        ).then((e) => {
            const url = window.URL.createObjectURL(
                new Blob([e.data], { type: "*" })
            );

            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", `thirdparties.xlsx`);

            document.body.appendChild(link);

            link.click();
            link.remove();
        }).catch(() => {
            toast(props.t("An error occurred while exporting as excel."), {
                type: "error",
            });

            setExcelExportInprocess(false)
        })
    }

    const tableFilterTabs = React.useMemo(() => {
        return [
            {
                type: 'date',
                name: 'filter-date',
                title: 'Creation Date',
                icon: <i className="ri-calendar-event-line" />,
                defaultValue: {
                    from: tableFilters?.createdAtFrom,
                    to: tableFilters?.createdAtTo
                }
            },
            {
                type: 'radio_list',
                name: 'legal',
                title: 'Connected Legal Entities',
                icon: <i className="ri-git-branch-line" />,
                isLoading: legalEntitiesFetching || legalEntitiesLoading,
                defaultValue: tableFilters?.legal,
                data: legalEntitiesData?.legalEntities || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'radio_list',
                name: 'country',
                title: 'Country',
                icon: <i className="ri-global-line" />,
                isLoading: countriesFetching || countriesLoading,
                defaultValue: tableFilters?.country,
                data: countriesData || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.name
                }
            },
            {
                type: 'radio_list',
                name: 'supplierStatus',
                title: 'Status',
                icon: <i className="ri-toggle-line" />,
                isLoading: false,
                defaultValue: tableFilters?.supplierStatus,
                data: [
                    {
                        id      :   0,
                        title   :   'New'
                    },
                    {
                        id      :   1,
                        title   :   'Under Review'
                    },
                    {
                        id      :   2,
                        title   :   'Approved'
                    },
                    {
                        id      :   3,
                        title   :   'Rejected'
                    }
                ],
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'radio_list',
                name: 'flag',
                title: 'Flag Status',
                icon: <i className="ri-flag-line" />,
                isLoading: false,
                defaultValue: tableFilters?.flag,
                data: [
                    {
                        id      :   1,
                        title   :   'On'
                    },
                    {
                        id      :   0,
                        title   :   'Off'
                    }
                ],
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'check_list',
                name: 'industries',
                title: 'Industry',
                icon: <i className="ri-building-line" />,
                isLoading: industriesFetching || industriesLoading,
                defaultValue: tableFilters?.industries,
                data: industriesData || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                onFilterChange: (industriesList) => {
                    setProductsFilter((currentState) => {
                        return {...currentState,pageIndex: 1, industries: industriesList}
                    })
                },
                formatter: (item) => {
                    return `${item.class} - ${props.t(item.title)}`
                }
            },
            {
                type: 'check_list',
                name: 'products',
                title: 'Products',
                icon: <i className="ri-product-hunt-line" />,
                isLoading: productsFetching || productsLoading,
                defaultValue: tableFilters?.products,
                data: productsData || [],
                pagination: {
                    enabled: true,
                    totalCountKey: 'itemsCount',
                    pageIndexKey: 'pageIndex',
                    pageSizeKey: 'pageSize',
                    dataKey: 'products',
                    handler: ({ pageIndex, pageSize }) => {
                        setProductsFilter((currentState) => {
                            return {...currentState, pageIndex: pageIndex, pageSize: pageSize}
                        })
                    }
                },
                searchInListOption: {
                    enabled: true,
                    remote: true,
                    defaultValue: productsFilter?.productName,
                    handler: (value) => {
                        setProductsFilter((currentState) => {
                            return {...currentState, pageIndex: 1, productName: value}
                        })
                    }
                },
                formatter: (item) => {
                    return `${item.class} - ${props.t(item.name)}`
                }
            }
        ]
    }, [
        tableFilters.createdAtFrom,
        tableFilters.createdAtTo,
        tableFilters.supplierStatus,
        tableFilters.country,
        tableFilters.flag,
        tableFilters.legal,
        tableFilters.industries,
        tableFilters.products,
        countriesFetching,
        countriesLoading,
        legalEntitiesFetching,
        legalEntitiesLoading,
        industriesFetching,
        industriesLoading,
        productsFilter,
        productsFetching,
        productsLoading,
        productsData,
        props.t
    ]);

    useEffect(() => {
        if(handleFetchUsersQuery.data){
            setUsersList(handleFetchUsersQuery?.data?.thirdParties || []);

            setTableResultTotalCount( handleFetchUsersQuery.data.itemsCount || 0);
        }
    }, [handleFetchUsersQuery.data]);

    useEffect(() => {
        handleFetchUsersQuery.refetch();
    }, [ tableFilters ]);

    useEffect(() => {
        if(selectedSupplierToExport && selectedSupplierToExport.id){
            fetchSupplierExportData()
        }
    }, [selectedSupplierToExport]);

    useEffect(() => {
        if(actionsRef && actionsRef.current){
            actionsRef.current = {
                reloadList : () => {
                    handleFetchUsersQuery.refetch();
                }
            };
        }
    }, []);

    useEffect(() => {
        refetchIndustriesProducts()
    }, [productsFilter])

    const renderUserStatus = (status) => {
        const statusInfos = SupplierStatuses[status];

        return (
            <div className={`badge p-2 ${statusInfos?.color}`}>
                { props.t(statusInfos?.title || 'Unkown') }
            </div>
        );
    }

    const renderUserActions = (item) => {
        return (
            <div className="actions">
                <button onClick={() => {
                    setSelectedSupplierToEditId(item.id)
                }} className="btn btn-small text-primary">
                    <i className="ri-pencil-line"></i>
                </button>

                <button className="btn btn-small text-primary" onClick={() => {
                    setSelectedOrgLink(item.third_party_link);
                    setShowThirdpartyLinkModal(true)
                }}>
                    <i className="ri-qr-code-line"></i>
                </button>

                <button className="btn btn-small text-primary" onClick={() => {
                    setSelectedSupplierToExport(item)
                }}>
                    <i className="ri-download-2-line"></i>
                </button>
            </div>
        )
    }

    const handleTableChange = (type, {
        page,
        sizePerPage
    }) => {
        switch(type){
            case 'pagination':
                    setTableFilters({
                        ...tableFilters,
                        pageIndex   :   page,
                        pageSize    :   sizePerPage
                    });
                break;
            default:
                return false;
        }
    }

    const renderModals = () => {
        const { t } = props;

        const toggleRemoveFlagConfirmModal = () => setSupplierIDToRemoveFlag(null);

        const mutationInProcess = handleToggleSupplierFlagMutation.isFetching || handleToggleSupplierFlagMutation.isLoading;

        return (
            <>
                <CreateModal 
                    modal={showCreateModal}
                    setModal={setShowCreateModal}
                    onComplete={() => {
                        setShowCreateModal(false);
                    }}
                />

                <Modal isOpen={ !!supplierIDToRemoveFlag } toggle={ toggleRemoveFlagConfirmModal }>
                    <ModalHeader toggle={ toggleRemoveFlagConfirmModal }>
                        { t('You are removing flag') }
                    </ModalHeader>
                    
                    <ModalBody>
                        {
                            `${t('Do you like to remove this supplier from the monitoring system')}?`
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button disabled={ mutationInProcess } onClick={ () => setSupplierIDToRemoveFlag(null) } color="secondary" outline>
                            {t('No')}
                        </Button>

                        <Button disabled={ mutationInProcess } onClick={ () => handleToggleSupplierFlagMutation.mutate(supplierIDToRemoveFlag) } color="primary">
                            {
                                mutationInProcess ? (
                                    <>
                                        <Spinner animation="border" variant="white" size="sm" className="me-1"/>
                                        {`${t('Updating')}...`}
                                    </>
                                ) : (
                                    <>{t('Yes')}</>
                                )
                            }
                        </Button>
                    </ModalFooter>
                </Modal>

                <EditSupplierModal 
                    closeModal={() => setSelectedSupplierToEditId(null)}
                    supplierId={selectedSupplierToEditId}
                    onComplete={() => handleFetchUsersQuery.refetch()}
                />

                <Modal isOpen={ !!selectedSupplierToExport } toggle={ () => setSelectedSupplierToExport(null) }>
                    <ModalHeader toggle={ () => setSelectedSupplierToExport(null) }>
                        <div>
                            <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                            {t('Third party due diligence report')}
                        </div>
                    </ModalHeader>
                    
                    <ModalBody>
                        {t('Exporting as PDF')}...
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ () => setSelectedSupplierToExport(null) } color="secondary" outline>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    return (
        <div className="list-suppliers p-4">
            <div className="d-flex justify-content-end mb-3 mt-3">
                <TableFilter
                    className="me-2"
                    defaultActiveTab='filter-date'
                    tabs={tableFilterTabs}
                    onOkButtonClicked={
                        onTableFilterOkButtonClicked
                    }
                />

                <Link className='btn btn-primary btn-outline me-2' to={"/admin/3rd-party/suppliers/create"}>
                    <i className="ri-add-line align-middle me-2" />
                    {props.t("Add Third Party")}
                </Link>

                <Button color="primary" outline className="me-2" disabled={excelExportInprocess} onClick={handleExportExcel}>
                    {
                        excelExportInprocess ? (
                        <>
                            <Spinner animation="border" variant="secondary" size="sm"/>{' '}
                            { props.t("Exporting") }...
                        </>
                        ) : (
                            <>
                                <i className="ri-file-excel-2-line align-middle me-2" />
                                { props.t("Export Excel") }
                            </>
                        )
                    }
                </Button>

                <Button disabled={isUploadingCSV} color="primary" outline
                    onClick={() => openUploadDialog(uploadCSV.mutate)} >
                        {
                            isUploadingCSV ? (
                            <>
                                <Spinner animation="border" variant="secondary" size="sm"/>{' '}
                                { props.t("Uploading...") }
                            </>
                            ) : (
                                <>
                                    <i className="ri-upload-2-line align-middle me-2" />
                                    { props.t("Upload CSV") }
                                </>
                            )
                        }
                </Button>
            </div>

            <PaginationProvider
                pagination={
                    paginationFactory({
                        custom              :   true,
                        page                :   tableFilters.pageIndex,
                        sizePerPage         :   tableFilters.pageSize,
                        totalSize           :   tableResultTotalCount,
                        withFirstAndLast    :   false,
                        alwaysShowAllBtns   :   true,
                        prePageText         :   <span><i className="ri-arrow-left-s-line"></i> {props.t('Back')}</span>,
                        nextPageText        :   <span>{props.t('Next')} <i className="ri-arrow-right-s-line"></i></span>,
                        prePageTitle        :   props.t('Pre page'),
                        firstPageTitle      :   props.t('Next page'),
                        showTotal           :   false,
                        paginationTotalRenderer :   (from, to, size) => {
                            return (
                                <span className="react-bootstrap-table-pagination-total">
                                    {`${props.t('Total Items')} ${size}`}
                                </span>
                            )
                        },
                        hideSizePerPage     :   false,
                        sizePerPageList     :   [
                            {
                                text: '25', 
                                value: 25
                            }, 
                            {
                                text: '50', 
                                value: 50
                            }
                        ]
                    })
                }>
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <React.Fragment>
                            <Row>
                                <Col sm="12">
                                    <BootstrapTable
                                        remote={{
                                            pagination: true,
                                            filter: false, 
                                            sort: true,
                                            cellEdit: false,
                                            search: false
                                        }}
                                        loading={ handleFetchUsersQuery.isFetching || handleFetchUsersQuery.isLoading }
                                        overlay={ overlayFactory({ spinner: <Spinner animation="border" variant="primary" size="md"/>, text : "Loading..." }) }
                                        onTableChange={ handleTableChange }
                                        defaultSorted={[]}
                                        keyField={"id"}
                                        responsive
                                        bordered={ false }
                                        data={ usersList }
                                        striped={true}
                                        columns={tableColumns}
                                        wrapperClasses="table-responsive"
                                        classes={"table tpdd-table"}
                                        headerWrapperClasses={"thead-light"}
                                        style={{
                                            overflowX: "auto",
                                        }}
                                        noDataIndication={ () => <NoDataIndication /> }
                                        { ...paginationTableProps }
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12">
                                    <div className="tpdd-pagination-style-1">
                                        <PaginationTotalStandalone
                                            { ...paginationProps }
                                        />
                                        
                                        <div>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                            
                                            <SizePerPageDropdownStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }

            </PaginationProvider>

            <Modal size="md" isOpen={ showThirdpartyLinkModal } className="link-qr-code-modal">
                
                <ModalHeader className="border-0" toggle={() => setShowThirdpartyLinkModal(!showThirdpartyLinkModal)}>
                    {props.t("In order to access,")}<br/>
                    {props.t('scan the QR code or click on the link below')}
                </ModalHeader>
                
                <ModalBody>
                    <Row>
                        <Col sm={12} className="col-sm-12 d-flex flex-column align-items-center">
                            <QRCode
                                className="link-qrcode mb-4"
                                value={selectedOrgLink}
                            />

                            <Button color="gray" outline block className={`w-100 ${linkIsCopied ? 'text-success' : 'text-primary'}`} style={{
                                backgroundColor: '#E0E0E0'
                            }} onClick={ copyLinkToClipboard }>
                                {
                                    clipboardIsAvailable ? (
                                        <>{ props.t(linkIsCopied ? 'Link Copied' : 'Copy Link') }</>
                                    ) : (
                                        <span className="user-select-all">{ selectedOrgLink }</span>
                                    )
                                }
                                
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            { renderModals() }
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
      user : state.Login.user,
      token,
      organization : Organization
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(List)))
);