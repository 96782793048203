import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { withNamespaces } from "react-i18next";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/components/constants";
import HandIcon from "../HandIcon";
import { TasksPriorities, TasksStatuses } from "src/modules/3rd-party-management/constants";
import HTMLContent from "../HTMLContent";

const dateUtils = new DateUtils();

const styles = StyleSheet.create({
    section: {
        padding: '0px 50px',
        height: 'auto'
    },
    sectionTitle: {
        fontWeight: 600,
        marginBottom: 10,
        fontSize: 13,
    },
    subsectionTitle: {
        fontWeight: "bold",
        fontSize: 11,
        left: 10,
        marginTop: 10,
        marginBottom: 8,
    },
    note: {
        fontSize: 9,
        color: '#4472c4',
        padding: '0px 35px',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start'
    },
    sectionSeparator: {
        width: '100%',
        backgroundColor: '#4472c4',
        fontWeight: 600,
        fontSize: 13,
        color: 'white',
        padding: "4px 20px",
        marginBottom: 20,
        marginTop: '10px'
    },
    table: {
        display: "table",
        width: "auto",
        marginBottom: '10px',
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
    },
    tableRow: {
        flexDirection: "row",
        borderBottom: "1px solid #000"
    },
    tableLastRow : {
        borderBottom: 'none'
    },
    tableColHeader: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#fff",
        borderRight: '1px solid #000',
    },
    fontBold: {
        fontWeight: "600"
    },
    tableCol: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRight: '1px solid #000',
        paddingTop: 2,
        paddingBottom: 2
    },
    borderRightNone : {
        borderRight: 'none'
    },    
    tableCell: {
        margin: 5,
        fontSize: 8,
        flexDirection: "row",
        alignItems: "center",
        flexGrow: 1,
        flexShrink: 1, 
        flexBasis: 0, 
        maxWidth: "100%", 
        wordWrap: "break-word",
        whiteSpace: "normal",
        overflow: "hidden"
    },
    text: {
        fontSize: 10,
        marginBottom: 10,
    },
    alphListItem: {
        color: "blue",
        marginBottom: 12
    }
});

const renderLocations = (locations, t) => {
    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('a')}) ${t('Factory Sites / Branches')}`}
            </Text>

            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Type')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Location')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Address')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Added On')}</Text>
                    </View>
                </View>

                {locations.map((location, i) => {
                    const rowStyle = {
                        ...styles.tableRow,
                        ...(((i + 1) >= locations.length) && {...styles.tableLastRow})
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.type === 'manufacture' ? t('Factory') : t('Office')}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.city}, {t(location.countryName)}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.address}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {location.createdAt && dateUtils.convertDateToDate(location.createdAt, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}

const renderProducts = (products, t) => {
    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('b')}) ${t('List Of Products')}`}
            </Text>

            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>#</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Description')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Nace Code')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('ERP ID')}</Text>
                    </View>
                </View>

                {products.map((product, i) => {
                    const rowStyle = {
                        ...styles.tableRow,
                        ...(((i + 1) >= products.length) && {...styles.tableLastRow})
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {i + 1}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {product.name?.name}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {product?.description}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {product?.name?.naceCode}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                --- 
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}

const renderContactPeople = (people, t) => {
    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('c')}) ${t('Contact Person')}`}
            </Text>
            
            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Position')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Email')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Phone')}</Text>
                    </View>
                </View>

                {people.map((person, i) => {
                    const rowStyle = {
                        ...styles.tableRow,
                        ...(((i + 1) >= people.length) && {...styles.tableLastRow})
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {`${t(person.title)} ${person.name} ${person.lastName}`}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.position}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.email}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.phoneNumber}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}

const renderManagementTeam = (people, t) => {
    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('d')}) ${t('Management')}`}
            </Text>
        
            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Position')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Email')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Phone')}</Text>
                    </View>
                </View>

                {people.map((person, i) => {
                    const rowStyle = {
                        ...styles.tableRow,
                        ...(((i + 1) >= people.length) && {...styles.tableLastRow})
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {`${t(person.title)} ${person.name} ${person.lastName}`}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.position}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.email}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {person.phoneNumber}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}

const renderShareholders = (holders, t) => {
    return (
        <View style={styles.section}>
            <Text style={styles.alphListItem}>
                {`${t('e')}) ${t('Shareholders')}`}
            </Text>

            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Share in')} %</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Date of birth')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Country of Residence')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('UBO')}(Y/N)</Text>
                    </View>
                </View>

                {(holders?.individual || []).map((shareholder, i) => {
                    const rowStyle = {
                        ...styles.tableRow
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                {`${shareholder.title} ${shareholder.firstName} ${shareholder.lastName}`}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.percentage}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {dateUtils.convertDateToDate(shareholder.birthDay, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.residenceCountry.name}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.isUbo ? 'Y' : 'N'}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>

            <View style={styles.table} break>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Company Name')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Share in')} %</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Founded On')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Country')}</Text>
                    </View>
                    <View style={{
                        ...styles.tableColHeader,
                        ...styles.borderRightNone
                    }}>
                        <Text style={{
                            ...styles.tableCell,
                            ...styles.fontBold
                        }}>{t('Ultimate Parent')} (Y/N)</Text>
                    </View>
                </View>

                {(holders?.company || []).map((shareholder, i) => {
                    const rowStyle = {
                        ...styles.tableRow
                    }

                    return (
                        <View style={rowStyle} key={i}> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.companyName}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.percentage}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {dateUtils.convertDateToDate(shareholder.foundingDate, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.country.name}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {shareholder.isUbo ? 'Y' : 'N'}
                                </Text>
                            </View>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}

const renderFindingsSection = (news, t) => {
    return (
        <View>
            <View style={styles.sectionSeparator}>
                <Text>{t('FINDINGS')}</Text>
            </View>

            <View style={styles.note}>
                <HandIcon />
                <Text style={styles.ml1}>
                    {t('Please note that Open Source Findings are displayed here.')}
                </Text>
            </View>

            <View style={styles.section} break>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Source')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Description')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Source Scoring')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Relevant')} (Y/N/Unknown)</Text>
                        </View>
                    </View>

                    {news.filter((finding) => finding.flag).map((finding, i) => {
                        const rowStyle = {
                            ...styles.tableRow
                        }

                        const relevancyScore = parseInt(finding?.relevancyScore || 0);

                        return (
                            <View style={rowStyle} key={i}> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {finding.news_agency}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        <HTMLContent content={finding.body} />
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {relevancyScore < 40 ? 'N' : (relevancyScore <= 69 ? 'Unknown' : 'Y')}
                                    </Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </View>
        </View>
    )
}

const renderRisksSection = (risks, t) => {
    return (
        <View break>
            <View style={styles.sectionSeparator}>
                <Text>{t('RISKS')}</Text>
            </View>

            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Rating')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('#')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Category')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Subcategory')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Risk Manager')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Risk Owner')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Mitigation Tasks')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Created On')}</Text>
                        </View>
                    </View>

                    {risks.map((risk, i) => {
                        const rowStyle = {
                            ...styles.tableRow,
                            ...(((i + 1) >= risks.length) && {...styles.tableLastRow})
                        }

                        return (
                            <View style={rowStyle} key={i}> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        R{risk.secondary_id}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.category.name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.category?.parent?.name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.manager}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.owner_name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {risk.tasks.map((t) => `T${t.secondary_id}`).join(', ')}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {dateUtils.convertTimeStampToDate(risk.created_at, INTERNATIONAL_DATE_FORMAT)}
                                    </Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </View>
        </View>
    )
}

const renderTasksSection = (tasks, t) => {
    return (
        <View break>
            <View style={styles.sectionSeparator}>
                <Text>{t('TASKS')}</Text>
            </View>
        
            <View style={styles.section} >
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('#')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Title')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Created On')}</Text>
                        </View>
                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Deadline')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Task Manager')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Task Owner')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Priority')}</Text>
                        </View>

                        <View style={{
                            ...styles.tableColHeader,
                            ...styles.borderRightNone
                        }}>
                            <Text style={{
                                ...styles.tableCell,
                                ...styles.fontBold
                            }}>{t('Status')}</Text>
                        </View>
                    </View>

                    {tasks.map((task, i) => {
                        const rowStyle = {
                            ...styles.tableRow,
                            ...(((i + 1) >= tasks.length) && {...styles.tableLastRow})
                        }

                        return (
                            <View style={rowStyle} key={i}> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        T{task.secondary_id}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {task.name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {dateUtils.convertTimeStampToDate(task.created_at, INTERNATIONAL_DATE_FORMAT)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {dateUtils.convertTimeStampToDate(task.ended_at, INTERNATIONAL_DATE_FORMAT)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {task.manager}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {task.analyst?.full_name}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {t(TasksPriorities[task.priority]?.title)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {t(TasksStatuses[task.status]?.title)}
                                    </Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </View>
        </View>
    )
}

const PageContent = (props) => {
    const { t, supplierData, noteBackgroundColor } = props;
    
    styles.sectionSeparator.backgroundColor = noteBackgroundColor;
    
    return (
        <View>
            { renderLocations(supplierData?.supplierDetail?.locations || [], t) }

            { renderProducts(supplierData?.products || [], t) }

            { renderContactPeople(supplierData?.contactPersons || [], t) }

            { renderManagementTeam(supplierData?.teamManagement || [], t) }

            { renderShareholders(supplierData?.shareHolders || {}, t) }

            { renderFindingsSection(supplierData?.news || [], t) }

            { renderRisksSection(supplierData?.risks || [], t) }

            { renderTasksSection(supplierData?.tasks || [], t) }
        </View>
    );
};

export default withNamespaces()(PageContent);
