import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class IndustriesService {
	static instance;

	static getInstance() {
    if (!IndustriesService.instance) {
      IndustriesService.instance = new IndustriesService();
    }

    return IndustriesService.instance;
	}

  async fetchList(params = {}) {
      const response = await axios.get(`${API_BASE_URL}/supplier/industry/list`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
            )}`,
          },
        }
      );

      return response.data.data;
  }

  async fetchProducts(params = {}) {
    const formattedIndustries = params.selectedIndustryIds?.join(',') || undefined;
    const response = await axios.get(`${API_BASE_URL}/supplier/industry/products_list`,
      {
        params: {
            ...params,
            industries: formattedIndustries,
            selectedIndustryIds: undefined
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data?.data || null;
  }
}
