import React from "react";

import { Document, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";

import { withNamespaces } from "react-i18next";

import { 
    FONT_OPEN_SANS_BOLD, 
    FONT_OPEN_SANS_REGULAR
} from "src/components/constants";

import DateUtils from "src/services/utils/DateUtils";

import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

import { now } from "moment";

import {
    RisksRates
} from "src/modules/3rd-party-management/constants";

import FirstPageHeader from "./components/FirstPageHeader";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PageContent from "./components/PageContent";
import HandIcon from "./components/HandIcon";
import HTMLContent from "./components/HTMLContent";

Font.register({
    family: "Open Sans",
    fonts: [{
            src: FONT_OPEN_SANS_REGULAR,
            fontWeight: 400,
            fontStyle: "normal",
        },
        {
            src: FONT_OPEN_SANS_REGULAR,
            fontWeight: 400,
            fontStyle: "italic",
        },
        {
            src: FONT_OPEN_SANS_BOLD,
            fontWeight: 600,
            fontStyle: "normal",
        },
        {
            src: FONT_OPEN_SANS_BOLD,
            fontWeight: 600,
            fontStyle: "italic",
        },
    ],
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        fontSize: 10,
        position: 'relative',
        fontFamily: 'Open Sans'
    },
    ml1: {
        marginLeft: 4
    },
    section: {
        flexGrow: 1
    },
    note: {
        fontSize: 9,
        color: '#4472c4',
        padding: '0px 35px',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start'
    },
    generalInfosRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 12
    },
    generalInfosRowCol: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    generalInfosTitle: {
        fontWeight: 'bold'
    },
    generalInfosValue: {
        color: '#4472c4',
        fontWeight: 'normal'
    },
    aiGeneralDescription: {
        lineHeight: 1.5
    },
    sections: {
        padding: "0px 30px",
    },
    sectionSeparator: {
        width: '100%',
        backgroundColor: '#4472c4',
        fontWeight: 600,
        fontSize: 13,
        color: 'white',
        padding: "4px 20px",
        marginBottom: 20
    },
    generalInfos: {
        marginBottom: 20
    }
});

const Supplier = (props) => {
	const { 
        t,
        organization,
        supplierData
    } = props;

	if (!supplierData) {
		return (
			<Document>
				<Page size="A4" style={styles.page1}></Page>
			</Document>
		);
	}

    const { primaryLogo, secondaryLogo } = organization;

    const primaryColor = organization?.primaryColor || "#5664d2";
    
    const logo = primaryLogo || secondaryLogo;
	
    const dateUtils = new DateUtils();

    const generateColor = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor?.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    const renderGeneralInfosSection = () => {
        const {
            supplierDetail
        } = supplierData;
        return (
            <>
                <View style={styles.sections}>
                    <View style={styles.generalInfos}>
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Name')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.name}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('SIP ID')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.id}</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Address')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {t(supplierDetail?.country)}, {supplierDetail?.city}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Phone')}:</Text>
                                <Text style={styles.generalInfosValue}></Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Website')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.websiteUrl}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Industry')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.industry?.name}</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Employee Size')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.employeeSize}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Vat ID')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.vatNumber}</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Overall Risk Rating')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {t(RisksRates[supplierDetail?.highestRisk?.toLowerCase()]?.title)}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Supplier Score')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.score}/10</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Last Update')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {supplierDetail?.lastUpdate && dateUtils.convertDateToDate(supplierDetail?.lastUpdate, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Report Printed')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {dateUtils.convertDateToDate(now(), INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Assigned Analyst')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {`${supplierDetail?.assignedAnalyst?.firsName} ${supplierDetail?.assignedAnalyst?.lastName}`}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            
                <View style={styles.sectionSeparator}>
                    <Text>
                        {t('GENERAL INFORMATION')}
                    </Text>
                </View>
            
                <View style={styles.note}>
                    <HandIcon />
                    <Text style={styles.ml1}>
                        {t('This summary is created by an AI and may be incomplete.')}
                    </Text>
                </View>
            
                <View style={styles.sections}>
                    <View style={styles.aiGeneralDescription}>
                        <HTMLContent content={supplierDetail.description} />
                    </View>
                </View>
            </>
        )
    }

    styles.sectionSeparator.backgroundColor = generateColor(primaryColor, 15);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <FirstPageHeader 
                    startColor={primaryColor} 
                    stopColor={generateColor(primaryColor, 15)} 
                    backgroundColor={primaryColor} 
                    logo={logo} 
                />

                <View>
                    {renderGeneralInfosSection()}
                </View>

                <Footer backgroundColor={primaryColor} />
            </Page>

            <Page size="A4" style={styles.page}>
                <Header 
                    startColor={primaryColor} 
                    stopColor={generateColor(primaryColor, 15)} 
                    backgroundColor={primaryColor} 
                    logo={logo} 
                />

                <View style={styles.section}>
                    <PageContent
                        supplierData={supplierData}
                        noteBackgroundColor={styles.sectionSeparator.backgroundColor}
                    />
                </View>

                <Footer backgroundColor={primaryColor} />
            </Page>
        </Document>
    );
      
};


export default withNamespaces()(
    Supplier
);
