import { withRouter, useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import OverviewHeader from "../../components/overview-header";
import Accordion from 'react-bootstrap/Accordion';

import {
    Col, Row, Label, Button, Container, Modal, ModalHeader, ModalBody,
    Spinner
} from "reactstrap";

import DateUtils from "src/services/utils/DateUtils";

import {
    INTERNATIONAL_DATE_FORMAT
} from 'src/common/constants';

import uploadedFileIcon from 'src/assets/images/uploaded-file.svg';

import SupplierReportsService from "src/modules/3rd-party-management/apis/thirdparty/SupplierReportsService";

import QuestionnaireService from "src/modules/3rd-party-management/apis/QuestionnaireService";

import { QuestionnaireSectionsImages } from 'src/modules/3rd-party-management/constants/Common';

import ReportDetailsPDF from './export/PDF';

import { pdf } from "@react-pdf/renderer";

import { saveAs } from "file-saver";

const ReportDetails = (props) => {
    const {
        t,
        organization
    } = props;

    const { id : reportId } = useParams();

    const [ activeAccordion, setActiveAccordion ] = useState(null);

    const [ exportPDFInprocess, setExportPDFInprocess ] = useState(false);

    const dateUtils = new DateUtils();

    const handleFetchSupplierReportDetailsQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-report-details'],
		queryFn: async () => {
			const service = SupplierReportsService.getInstance();

            return await service.details(reportId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching report details.'), {
				type: 'error',
			});
		},
	});

    const {
        refetch: fetchReportPDFData
    } = useQuery({
		queryKey: ['tpdd-thirdparty-create-report-pdf-details-query'],
		queryFn: async () => {
            setExportPDFInprocess(true);
			const service = SupplierReportsService.getInstance();

            return await service.fetchPDFData(reportId);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: (error) => {
			toast(t('An error occurred while fetching report details.'), {
				type: 'error',
			});

            setExportPDFInprocess(false);
		},
        onSuccess: async (data) => {
            const fileName = `report-${reportId}.pdf`;
            const blob = await pdf( 
                <ReportDetailsPDF 
                    organization={organization} 
                    data={{
                        sections:  groupQuestionsBySection(data)
                    }}
                /> 
            ).toBlob();
        
            saveAs(blob, fileName);

            setExportPDFInprocess(false);
        }
	});

    const findQuestionAnswers = (question) => {
        const answers = handleFetchSupplierReportDetailsQuery?.data || [];

        const questionIdToSearch = !question?.isReactSubQuestion ? question.id : question.parentQuestionId;

        const item = answers.find((questionData) => {
            return questionData.id === questionIdToSearch;
        });

        if(item){
            if(!question?.isReactSubQuestion){
                switch(question.type){
                    case 'textarea':
                    case 'text':
                    case 'date':
                    case 'select':
                    case 'country_list':
                        return item.answer[0]?.title;

                    case 'checkbox':
                    case 'category':
                    case 'file_upload':
                        return item.answer || [];

                    case 'radiobox':
                        const userAnswer = (item.answer && Array.isArray(item.answer) && item.answer.length > 0) ? item.answer[0] : null;

                        if(userAnswer){
                            const optionBaseInfos = question.options.find((option) => {
                                return option.id === userAnswer.value;
                            });

                            if(optionBaseInfos){
                                return {
                                    value       :   optionBaseInfos.id,
                                    title       :   optionBaseInfos.title,
                                    subTitle    :   (optionBaseInfos.type === 'date' || optionBaseInfos.type === 'text') ? userAnswer.title : null
                                };
                            }
                        }
                    break;
                    
                    default:
                        return null;
                }
            }
            else{
                const answer = item.answer[0];

                if(answer && answer?.children && Array.isArray(answer.children)){
                    let preFilledSubQuestionData = answer.children.find((subQuestionData) => {
                        return Number.parseInt(subQuestionData.id) === question.id;
                    });

                    if(preFilledSubQuestionData?.type === 'file_upload'){
                        return [{
                            title   :   preFilledSubQuestionData?.title,
                            name    :   preFilledSubQuestionData?.name
                        }];
                    }

                    return preFilledSubQuestionData?.title;
                }
            }
        }

        return null;
    }

    const groupQuestionsBySection = (questions) => {
        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];
            let section = findSection(question.section || '');

            if(!section){
                section = {
                    name : question.section || '',
                    items : []
                }
                groupedQuestionsBySection.push(section);
            }

            section.items.push(question);
        }

        return groupedQuestionsBySection;
    }

    const renderLoadingSection = () => {
        return (
            <React.Fragment>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg="12">
                        <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="6">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                    <Col lg="8">
                        <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    const renderQuestionsGroup = (items, settings = {
        displayNumbers  :   true
    }) => {
        return items.map((question, index) => {
            const {
                title, type
            } = question;

            const userAnswers = findQuestionAnswers(question);

            if (type === "textarea" || type === "text" || type === "country_list" || type === "select") {
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>
                        <p>
                            { userAnswers }
                        </p>
                    </div>
                );
            }
            else if(type === "date"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>
                        <p>
                            {userAnswers && dateUtils.convertDateToDate(
                                userAnswers,
                                INTERNATIONAL_DATE_FORMAT
                            )}
                        </p>
                    </div>
                )
            }
            else if(type === "checkbox" || type === "category"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>
                        <Row>
                            {
                                userAnswers.map((eachCheckboxAnswer, checkboxIndex) => {
                                    return (
                                        <Col sm="12" md="6" key={ checkboxIndex }>
                                            <p>
                                                {
                                                    t(eachCheckboxAnswer?.title)
                                                }
                                            </p>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </div>
                );
            }
            else if(type === "radiobox"){
                const selectedOptionInfos = (question.options || []).find((o) => o.id === userAnswers?.value);

                const children = ((selectedOptionInfos?.children && selectedOptionInfos.children.length > 0) ? selectedOptionInfos?.children : []).map((child) => {
                    return {
                        ...child, 
                        isReactSubQuestion  :   true,
                        parentQuestionId    :   question.id
                    };
                });

                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>
                        <Row>
                            {
                                userAnswers && (
                                    <Col sm="12">
                                        <Label className="form-label question-title">
                                            {t(userAnswers?.title)}
                                        </Label>
                                        <div className="sub-question-container">
                                            {
                                                (children.length > 0) && (
                                                    renderQuestionsGroup(children, {
                                                        displayNumbers: false
                                                    })
                                                )
                                            }
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                );
            }
            else if(type === "file_upload"){
                return (
                    <div className="question" key={ index }>
                        <Label className="form-label question-title">
                            { (settings.displayNumbers ? ((index + 1) + '. ') : '') } {t(title)}
                        </Label>

                        <div className="files-container">
                            {
                                (userAnswers || []).map((f, i) => {
                                    return (
                                        <div className="file-preview" key={i + "-file"}>
                                            <span className="file-name mb-2">
                                                { f.name }
                                            </span>
                                            <img src={ uploadedFileIcon } alt="" />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                );
            }

            return null;
        })
    }

    const renderExportPDFWaitingModal = () => {
        return (
            <Modal isOpen={ exportPDFInprocess } toggle={ () => setExportPDFInprocess(false) }>
                <ModalHeader toggle={ () => setExportPDFInprocess(false) }>
                    <div>
                        <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                        {t('Third party report')}
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    {t('Exporting as PDF')}...
                </ModalBody>
            </Modal>
        )
    }

    const questionnaireIsLoading = (
        handleFetchSupplierReportDetailsQuery.isFetching || handleFetchSupplierReportDetailsQuery.isLoading   
    );

    return (
        <div className='page-content report-details-page'>
            <Container fluid>
                <Row>
                    <Col sm="12" className="mb-4">
                        <OverviewHeader />
                    </Col>
                </Row>
                {
                    questionnaireIsLoading ? (
                        renderLoadingSection()
                    ) : (
                        <Row>
                            <Col sm="12" className="d-flex flex-row-reverse justify-content-start align-items-center mb-3">
                                <Button color="primary" onClick={fetchReportPDFData}>
                                    { t('Download Report PDF') }
                                </Button>
                            </Col>

                            <Col sm="12">
                                <Accordion defaultActiveKey={ activeAccordion } className="questionnaire">
                                    {
                                        groupQuestionsBySection(handleFetchSupplierReportDetailsQuery.data || []).map((section, index) => {
                                            return (
                                                <Accordion.Item onClick={() => setActiveAccordion(index)} className={activeAccordion === index ? 'active' : ''} eventKey={ index }>
                                                    <Accordion.Header>
                                                        <img src={QuestionnaireSectionsImages[`${(section.name.toString()).replaceAll(' ', '_').replaceAll('-', '_').toLowerCase()}`]} alt="" />

                                                        <span>{`${index + 1}. ${section.name}`}</span>
                                                    </Accordion.Header>
                                                    
                                                    <Accordion.Body>
                                                        { renderQuestionsGroup(section.items) }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            </Col>

                            { renderExportPDFWaitingModal() }
                        </Row>
                    )
                }
            </Container>
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    const { token } = state.Login;

    return {
        user : state.Login.user,
        organization : Organization,
        token
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps,
        {}
    )(memo(ReportDetails)))
);