import { debounce } from "lodash";
import { withNamespaces } from "react-i18next";
import { memo, useEffect, useState } from "react";
import { Col, Row, Button } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  errorNotification,
  successNotification,
} from "../../../../../../store/actions";
import QuestionarService from "../../../../components/Questionar/service";
import DataProtectionSoftwareService from "../../../../apis/DataProtectionSoftwareService";
import Questionar from "../../../../components/Questionar";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// styles
import "./style.scss";
import { API_BASE_URL } from "../../../../constants";
import OverviewHeader from "../../overview-header";
import wrongIcon from "../../../../assets/images/wrongIcon.svg";
import greenTick from "../../../../assets/images/greenTick.svg";
import DeletionModal from "./DeletionModal";

const DataProtectionSoftwareProcessingActivity = (props) => {
  const { t } = props;
  const [filledQuestionnaires, setFilledQuestionnaires] = useState([]);
  const [showAccordion, setShowAccordion] = useState(false);
  const [accordionTitle, setAccordionTitle] = useState("");
  const [isAccordionTitleSet, setIsAccordionTitleSet] = useState(false);
  const [isCreateNewFormSubmiting, setIsCreateNewFormSubmiting] =
    useState(false);
  const [isUpdateFormSubmiting, setIsUpdateFormSubmiting] =
    useState(false);
  const [isWrongIconVisible, setIsWrongIconVisible] = useState(true);

  const [editableTitles, setEditableTitles] = useState([]);

  const [resetCreateNewForm, setResetCreateNewForm] = useState(false);
  const [sectionProgress, setSectionProgress] = useState({});
  const [childrenAnswers, setChildrenAnswers] = useState([]);
  const [yesAnswers, setYesAnswers] = useState([]);
  const [newCaseId, setNewCaseId] = useState(null);

  const [isNewAccordionCreated, setIsNewAccordionCreated] = useState(false);

  const [deletionModal, setDeletionModal] = useState(false);
  const [deletionCaseId, setDeletionCaseId] = useState(null);

  const [defaultValues, setDefaultValues] = useState([]);

  const { softwareId } = useParams();

  const handleFetchQuestions = useQuery({
    queryKey: ["data-protection-softwares-questions-list"],
    queryFn: async () => {
      const service = QuestionarService.getInstance();

      return await service.questions(
        "processing_activity",
        props.Organization.id,
        {
          softwareId: softwareId,
        }
      );
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching questions."), {
        type: "error",
      });
    },
  });

  const handleFetchFilledItems = useQuery({
    queryKey: ["data-protection-softwares-filled-items"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();

      return await service.filledQuestionnaires(
        softwareId,
        "processing_activity"
      );
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching questions."), {
        type: "error",
      });
    },
  });

  const setChilderenOptionsForRadioBoxes = (questions) => {
    const selectedQuestion = defaultValues.filter(value => value.case_id === newCaseId);
    const answers = selectedQuestion[0]?.question_answers;
    
    
    if(selectedQuestion && answers && questions) {
      questions.map((question => {
        const questionId = question?.id
        const preFilledQuestionData =
        answers && Array.isArray(answers)
          ? 
          answers.filter((questionData) => {
              return questionData.id === question.id;
            })[0]
          : null;         
        
           if (preFilledQuestionData && preFilledQuestionData.answers) {
            if (!question?.isReactSubQuestion) {
            switch (question.type) {
             case "radiobox":
              if(question && question?.options, preFilledQuestionData) {
                const options = question.options;
                
                options.map(option => {
                  const isChildren = option?.children?.length > 0 ||
                  (option?.extended_title &&
                    (option?.type === "country" || option?.type === "country_multi_select"));
                  const isExtended = option?.extended_title && (option?.type !== "country" && option?.type !== "country_multi_select")
                            ? true
                            : false

                  const type = option?.type;
                  if(isChildren && (type === "multi_select" || type === "country_multi_select")) {
                    setChildrenAnswers((prevAnswers) => {
                      const index = prevAnswers?.findIndex(
                        (answer) => answer.questionId === questionId
                      );
                      const childData = preFilledQuestionData.answers[0];
                      
                      const selectedValue = childData?.children; 
                      const value = option.id;

                      const transformedChildData = !Array.isArray(selectedValue) 
                        ? [{ title: childData?.title, value: value }]
                        : selectedValue?.map((child) => {
                            const { title, ...rest } = child;
                            return {
                              ...rest,
                              title: title,
                              value: value,
                            };
                          });
                          
                      if (index >= 0) {
                        return prevAnswers.map((answer, idx) =>
                          idx === index ? { ...answer, children: transformedChildData } : answer
                        );
                      } else {
                        return [
                          ...prevAnswers,
                          { questionId: questionId, children: transformedChildData },
                        ];
                      }
                    });
                  } else if(isExtended && preFilledQuestionData.answers[0]?.title !== "Yes" && (preFilledQuestionData.answers[0]?.title !== "No" && type === "text") && preFilledQuestionData.answers[0]?.title !== "External") {
                    
                    const title = preFilledQuestionData.answers[0]?.title

                    setYesAnswers((prevAnswers) => {
                      const index = prevAnswers.findIndex(
                        (answer) => answer.question === question.id
                      );

                      
                      if (index >= 0) {
                        return prevAnswers.map((answer, idx) =>
                          idx === index ? { ...answer, title: title, value: option?.id } : answer
                        );
                      } else {
                        return [
                          ...prevAnswers,
                          { question: question?.id, title: title, value: option?.id },
                        ];
                      }
                    });

                    
                  }
                })
              }
              
                return preFilledQuestionData?.answers[0];
              }
            } else {
              const answer = preFilledQuestionData.answers[0];
              if (answer && answer?.children && Array.isArray(answer.children)) {
                let preFilledSubQuestionData = answer.children.find(
                  (subQuestionData) => {
                    return Number.parseInt(subQuestionData.id) === question.id;
                  }
                );
      
                if (preFilledSubQuestionData?.type === "file_upload") {
                  return [
                    {
                      title: preFilledSubQuestionData?.title,
                      name: preFilledSubQuestionData?.name,
                    },
                  ];
                }
      
                return preFilledSubQuestionData?.title;
              }
            }
          }}))
      
    }
  }

  useEffect(() => {
    const questions = handleFetchQuestions.data;
    setChilderenOptionsForRadioBoxes(questions);
  }, [newCaseId])

  useEffect(() => {
    const reports =
      handleFetchFilledItems.data && handleFetchFilledItems.data.reports
        ? handleFetchFilledItems.data.reports
        : [];
    const results = []
    
    reports.forEach(report => {
      return results.push({
        caseId: report?.case_id,
        answers: report?.question_answers,
        isEmpty: report?.is_empty
      })
    })
    
    setDefaultValues(reports)

    const currentCaseIds = filledQuestionnaires.map(item => item.caseId);

    const hasChanged = reports.some(report => !currentCaseIds.includes(report.case_id));

    if (hasChanged) {
      setFilledQuestionnaires(reports);
    }
    // if(reports !== filledQuestionnaires) {
    //   setFilledQuestionnaires(reports)
    // }
    
  }, [handleFetchFilledItems.data]);


  const handleAddNewActivityClick = () => {
    setIsWrongIconVisible(true);
    setShowAccordion(true); // Set showAccordion to true when "Add New Activity" button is clicked
  };

  const handleUpdateQuestionnaire = async (data, caseId, is_empty=true) => {
    const updatedQuestionAnswers = data.questionAnswers.map((qa) => {
      
      const childrenAnswer = childrenAnswers.find(
        (ca) => ca.questionId === qa.question
      );

      // Find the corresponding yesAnswer entry by questionId
      const yesAnswer = yesAnswers.find((ya) => ya.question === qa.question);
      // If a corresponding yesAnswer is found, replace the answer with the new structure
      if (yesAnswer) {
        return {
          question: qa.question,
          answer: [
            {
              title: yesAnswer.title,
              value: yesAnswer.value,
            },
          ],
        };
      }

      // If a corresponding childrenAnswer entry is found, update the answer object by adding children directly to it
      if (childrenAnswer && childrenAnswer.children) {
        return {
          ...qa,
          answer: qa.answer.map((ans) => ({
            ...ans,
            children: childrenAnswer.children, // Append children to each answer
          })),
        };
      }
      // If no corresponding entry is found, return the original question answer entry
      return qa;
    });
    
    const payload = is_empty ? 
    {
      questionAnswers: updatedQuestionAnswers,
      reportCase: caseId
    }
    :
    {
      ...data,
      software: softwareId,
      questionAnswers: updatedQuestionAnswers,
      hardware: null,
      reportCase: caseId
    };

    setIsCreateNewFormSubmiting(true);
    
    axios
      .post(`${API_BASE_URL}/${is_empty ? "processing_activity/create" : "report.update"}`, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        setIsNewAccordionCreated(true);
        props.successNotification({
          code: props.t("Report Updated"),
          message: props.t("Report Updated"),
        });

        setResetCreateNewForm(true);
        
        handleFetchFilledItems.refetch();
      })
      .catch((error) => {
        props.errorNotification({
          code: "validation_error",
          message: props.t("Error!"),
        });
      })
      .finally(() => {
        setIsUpdateFormSubmiting(false);
      });
  };

  const createModuleTitle = (title, index) => {
    const payload = {
      moduleName: title,
      software: softwareId,
    };

    axios
      .post(`${API_BASE_URL}/processing_activity/add_module`, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res.status == "200") {
          toast(t("Title is added"));
          setIsAccordionTitleSet(true);
          setNewCaseId(res.data.data.report_case_id);
          if(index) {
            setEditableTitles((prev) =>
                  prev.filter((i) => i !== index)
                )
          }
        }
      })
      .catch((error) => {
        props.errorNotification({
          message: props.t("Faild to add the title"),
        });
      })
  }

  const updateModuleTitle = (title, case_id, index) => {
    const payload = {
      reportCase: case_id,
      moduleName: title,
      // software: softwareId,
    };

    axios
      .post(`${API_BASE_URL}/processing_activity/update_module`, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res.status == "200") {
          toast(t("Title is updated"), {
            type: "success"
          });
          setIsAccordionTitleSet(true);
          setFilledQuestionnaires((prev) => {
            const foundIndex = prev.findIndex((i) => i.case_id === case_id);
            if(foundIndex > -1) {
              prev[foundIndex].module_name = title
            }
            return [...prev]
          })
          setNewCaseId(res.data.data.report_case_id);
          if(index) {
            setEditableTitles((prev) =>
                  prev.filter((i) => i !== index)
                )
          }
        }
      })
      .catch((error) => {
        props.errorNotification({
          message: props.t("Faild to update the title"),
        });
      })
  }

  const handleSubmitSectionTitle = (case_id = null, title = accordionTitle, index=null) => {
    if(!case_id) {
      createModuleTitle(title, index);
    } else {
      updateModuleTitle(title, case_id, index);
    }
  };

  const handleDeleteActivity = (case_id) => {
    const url = `${API_BASE_URL}/processing_activity/remove`;

    const payload = {
      report_case_id: case_id,
    };

    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
        data: payload
      })
      .then((res) => {
        if (res.status == "200") {
          toast(t("Activity is removed"));
          setDeletionModal(false)
          handleFetchFilledItems.refetch();
        }
      })
      .catch((error) => {
        props.errorNotification({
          message: props.t("Faild to remove the activity"),
        });
      })
  }

  const questionareSettings = {
    confirmFilledInputsForm: {
      enabled: false,
    },
    buttons: {
      submit: {
        enabled: true,
        title: t("Send"),
      },
    },
  };

  const updateQuestionareSettings = {
    confirmFilledInputsForm: {
      enabled: false,
    },
    buttons: {
      submit: {
        enabled: true,
        title: t("Update"),
      },
    },
  };

  const debouncedQuestionComplete = debounce((sectionKey, questionId) => {
    const foundIndexChildren = childrenAnswers?.findIndex(ch => ch.questionId === questionId);
    const foundExtendedChildren = yesAnswers?.findIndex(ex => ex.question === questionId);
    if(foundIndexChildren > -1) {
        setChildrenAnswers(prevAnswers =>
          prevAnswers.filter((_, index) => index !== foundIndexChildren)
        );
    }

    if(foundExtendedChildren > -1) {
      setYesAnswers(prevAnswers =>
        prevAnswers.filter((_, index) => index !== foundExtendedChildren)
      );
    }
    setSectionProgress((prev) => {
      const updated = { ...prev };
      const section = updated[sectionKey];

      // Optional: Implement additional logic here if needed
      return section;
    });
  }, 300); // Delay in milliseconds, adjust as necessary

  const handleQuestionComplete = (sectionKey, questionId) => {
    debouncedQuestionComplete(sectionKey, questionId);
  };

  const handleChildrenChange = debounce((questionId, childData, value) => {
    setChildrenAnswers((prevAnswers) => {
      const index = prevAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );

      const transformedChildData = !Array.isArray(childData)
        ? [{ title: childData?.label, value: childData.value }]
        : childData.map((child) => {
            const { label, ...rest } = child;
            return {
              ...rest,
              title: label,
              value: value,
            };
          });

      if (index >= 0) {
        return prevAnswers.map((answer, idx) =>
          idx === index ? { ...answer, children: transformedChildData } : answer
        );
      } else {
        return [
          ...prevAnswers,
          { questionId: questionId, children: transformedChildData },
        ];
      }
    });
  }, 300); // Adjust debounce time as needed

  const handleExtendedChange = debounce((questionId, e, value) => {
    setYesAnswers((prevAnswers) => {
      const index = prevAnswers.findIndex(
        (answer) => answer.question === questionId
      );
      
      if (index >= 0) {
        return prevAnswers.map((answer, idx) =>
          idx === index ? { ...answer, title: e, value: value } : answer
        );
      } else {
        
        return [
          ...prevAnswers,
          { question: questionId, title: e, value: value },
        ];
      }
    });
  }, 100);
  
  return (
    <>
    <DeletionModal t={t} modal={deletionModal} setModal={setDeletionModal} handleDelete={handleDeleteActivity} caseId={deletionCaseId} />
      <Row>
        <Col sm="12">
          <div className="p-4">
            <OverviewHeader t={t} assetId={softwareId} />
            <div className="d-flex justify-content-end mt-4">
              <Button
                color="primary"
                outline
                onClick={handleAddNewActivityClick}
              >
                {t("Add New Activity")}
              </Button>
            </div>

            <Accordion
              className="mt-4 processingAcitivity"
              defaultActiveKey={["new-report"]}
            >
              {showAccordion && !isNewAccordionCreated && ( // Render Accordion only when showAccordion is true
                <Accordion.Item
                  className="border-none mb-2"
                  eventKey={"new-report"}
                >
                  {isWrongIconVisible &&
                  <Accordion.Header className="bg-white-accordion">
                    {!isAccordionTitleSet ? (
                      <>
                       <div style={{width: "90%"}}>
                        <input
                          type="text"
                          style={{ width: "30%" }}
                          value={accordionTitle}
                          onChange={(e) => setAccordionTitle(e.target.value)}
                          onBlur={() => accordionTitle ? null : setIsAccordionTitleSet(false)}
                          className="form-control"
                          placeholder="Enter activity title"
                        />
                      </div>
                        <img src={wrongIcon} onClick={() => {
                          setIsAccordionTitleSet(false);
                          setIsWrongIconVisible(false);
                          }} />
                        
                        <img src={greenTick} onClick={() => handleSubmitSectionTitle(null, accordionTitle)} />
                      </>
                    ) : (
                      <div onClick={() => setIsAccordionTitleSet(false)}>{accordionTitle}</div>
                    )}
                  </Accordion.Header>}

                  {isAccordionTitleSet ? (
                    <Accordion.Body>
                      <Questionar
                        questionnaireId={`new-report`}
                        questionnaireType={"processing_activity"}
                        loading={
                          handleFetchQuestions.isLoading ||
                          handleFetchQuestions.isFetching
                        }
                        questions={handleFetchQuestions.data || []}
                        // onValidSubmit={handleSubmit}
                        onValidSubmit={(e) => handleUpdateQuestionnaire(e, newCaseId)}
                        isFormSubmiting={isCreateNewFormSubmiting}
                        user={{
                          token: props.token,
                          ...props.user,
                        }}
                        resetFormModel={resetCreateNewForm}
                        settings={questionareSettings} 
                        onQuestionComplete={(id) =>
                          handleQuestionComplete("processing_activity", id)
                        }
                        onChildrenChange={(id, e, value) =>
                          handleChildrenChange(id, e, value)
                        }
                        onExtendedChange={(id, e, value) =>
                          handleExtendedChange(id, e, value)
                        }
                      />
                    </Accordion.Body>
                  ) : null}
                </Accordion.Item>
              )}
              {filledQuestionnaires.map((report, index) => {
                const { case_id, module_name, is_empty } = report;
                let editableValue = module_name;
                return (
                  <Accordion.Item
                    className="border-none mb-2"
                    eventKey={index}
                    key={index}
                  >
                    <Accordion.Header onClick={() => setNewCaseId(case_id)} className="bg-white-accordion">
                      {editableTitles.includes(index) ? (
                        <>
                        <div style={{ width: "100%" }}>
                          <input
                            type="text"
                            defaultValue={module_name}
                            onChange={(e) => (editableValue = e.target.value)}
                            // onBlur={() =>
                            //   setEditableTitles((prev) =>
                            //     prev.filter((i) => i !== index)
                            //   )
                            // }
                            className="form-control"
                            autoFocus
                            style={{ width: "50%" }}
                          />
                        </div>
                          {/* <Button
                            color="primary"
                            outline
                            onClick={() =>
                              handleSubmitSectionTitle(case_id, editableValue)
                            }
                          >
                            {t("Save")}
                          </Button> */}
                          <img src={wrongIcon} onClick={() => setEditableTitles((prev) =>
                                prev.filter((i) => i !== index)
                              )} />
                          <img src={greenTick} onClick={() => handleSubmitSectionTitle(case_id, editableValue, index)} />
                        </>
                      ) : (
                        <>
                          <div
                            style={{width: "90%"}}
                            onClick={() =>
                              setEditableTitles((prev) => {
                                if (!prev.includes(index)) {
                                  return [...prev, index];
                                }
                                return prev;
                              })
                            }
                          >
                            {module_name}
                          </div>
                          <div style={{width: "10%"}} className="me-5" onClick={() => {
                            setDeletionModal(true);
                            setDeletionCaseId(case_id)
                          }}>
                            <Button style={{color: "red", background: "none"}}  outline >{t("Delete")}</Button>
                          </div>
                        </>
                      )}
                    </Accordion.Header>
                    <Accordion.Body className="pt-3">
                      <Questionar
                        questionnaireId={`report-${index}`}
                        questionnaireType={"processing_activity"}
                        questions={handleFetchQuestions.data || []}
                        onValidSubmit={(e) => handleUpdateQuestionnaire(e, case_id, is_empty )}
                        isFormSubmiting={isUpdateFormSubmiting}
                        preFilledAnswers={report?.question_answers}
                        user={{
                          token: props.token,
                          ...props.user,
                        }}
                        settings={updateQuestionareSettings}
                        onQuestionComplete={(id) =>
                          handleQuestionComplete("processing_activity", id)
                        }
                        onChildrenChange={(id, e, value) =>
                          {
                            handleChildrenChange(id, e, value)
                          }
                        }
                        onExtendedChange={(id, e, value) =>
                          {
                            handleExtendedChange(id, e, value)
                          }
                        }
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { token, user } = state.Login;
  const { Organization } = state;

  return {
    token,
    user,
    Organization,
  };
};

export default withNamespaces()(
  connect(mapStatetoProps, {
    errorNotification,
    successNotification,
  })(memo(DataProtectionSoftwareProcessingActivity))
);
