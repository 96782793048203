import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { errorNotification, successNotification } from "src/store/actions";
import { Fragment, memo, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";
import CommonService from "src/modules/data-protection/apis/CommonService";
import ReportsService from "src/modules/data-protection/apis/ReportsService";
// icons
import flagOnIcon from "src/modules/data-protection/assets/icons/flag-icon.png";
import flagOffIcon from "src/modules/data-protection/assets/icons/flag-icon-off.png";
import highPriorityIcon from "src/modules/data-protection/assets/icons/priority-high.png";
import mediumPriorityIcon from "src/modules/data-protection/assets/icons/priority-medium.png";
import lowPriorityIcon from "src/modules/data-protection/assets/icons/priority-low.png";
// styles
import "./style.scss";
// components
import CustomTable from "src/components/CustomTable";
import Pagination from "src/modules/data-protection/components/pagination";
import PageBody from "src/modules/data-protection/components/page-body";
import PageHeader from "src/modules/data-protection/components/page-header";
import PageContent from "src/modules/data-protection/components/page-content";
import Select from "react-select";

import useUploadCSV from "src/modules/data-protection/hooks/useUploadCSV";

import Deletion from "src/modules/data-protection/pages/software/modal/deletion";

import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router-dom";
import DateUtils from "src/services/utils/DateUtils";
import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";
import { REPORT_STATUS_LIST } from "src/components/constants";

const DataProtectionSoftwareRelatedIncidents = (props) => {
  const location = useLocation();
  const history = useHistory();

  const dateUtils = new DateUtils();
  const { t, assetId } = props;

  const SoftwareStatuses = {
    NEW: t("New"),
    IN_PROGRESS: t("In Progress"),
    OPEN: t("Open"),
    APPROVED: t("Approved"),
    ASSIGNED: t("Assigned"),
    DELETED: t("Deleted"),
  };

  const [softwares, setSoftwares] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);

  const [showDeletionModal, setDeletionModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deletionComplete, setDeletionComplete] = useState(false);

  const [filterStatus, setFilterStatus] = useState({
    label: t("Select Status"),
    value: "all",
  });
  const [filterFlag, setFilterFlag] = useState({
    label: t("Select Flag"),
    value: "all",
  });
  const [filterAssetOwner, setFilterAssetOwner] = useState({
    label: t("Asset Owner"),
    value: "all",
  });

  const [users, setUsers] = useState([]);

  const tableHeader = [
    [
      {
        value: (
          <p className="text-truncate text-capitalize text-left">
            {props.t("Risk Rating")}
          </p>
        ),
        width: "5%",
      },
      // {
      //   value: (
      //     <p className="text-truncate text-capitalize text-left">
      //       {props.t("Type")}
      //     </p>
      //   ),
      //   width: "25%",
      // },
      {
        value: (
          <p className="text-truncate text-capitalize text-left">
            {props.t("Incident ID")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Description")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Submission Date")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Closing Date")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Assign Analyst")}
          </p>
        ),
        width: "10%",
      },
      {
        value: (
          <p className="text-truncate text-capitalize text-center">
            {props.t("Status")}
          </p>
        ),
        width: "10%",
      },
      // {
      //   value: (
      //     <p className="text-truncate text-capitalize text-center">
      //       {props.t("Operating System")}
      //     </p>
      //   ),
      //   width: "10%",
      // },
      // {
      //   value: <p className="text-truncate text-capitalize text-center"></p>,
      //   width: "5.3%",
      // },
    ],
  ];

  const handleFetchIncidentListQuery = useQuery({
    queryKey: ["data-protection-incidents-list", pageIndex, pageSize],
    queryFn: async () => {
      const service = ReportsService.getInstance();

      return await service.list(pageIndex, pageSize, {
        software: Number(assetId),
      });
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);

      toast(t("An error occurred while fetching incidents."), {
        type: "error",
      });
    },
  });

  const fetchUsers = useQuery({
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);

      toast(t("An error occurred while fetching users."), {
        type: "error",
      });
    },
  });

  const toggleSoftwareFlagMutation = useMutation({
    mutationFn: async ({ softwareId }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.toggleFlag({
        softwareId: softwareId,
      });
    },
    onSuccess: () => {
      toast(t("Software flag status successfully changed."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while changing flag status."), {
        type: "error",
      });
    },
    onMutate: ({ softwareId }) => {
      setSoftwares({
        ...softwares,
        assets: softwares.assets.map((software) => {
          if (software.id === softwareId) {
            return {
              ...software,
              flagIsUpdating: true,
            };
          }
          return software;
        }),
      });
    },
  });

  const onRemoveClick = (id) => {
    setSelectedId(id);
    setDeletionModal(true);
  };

  const handleRenderReportStatus = (statusId) => {
    const status = REPORT_STATUS_LIST.find(
      (item) => item.id === parseInt(statusId)
    );
    return (
      <div className={`${status.className} p-2`} style={{ minWidth: "78px" }}>
        {t(status.titleKey)}
      </div>
    );
  };

  const handleProvideTableData = (softwares) => {
    return softwares.map((software) => {
      return [
        {
          value: (
            <p className="text-truncate text-capitalize">
              <span
                style={{ backgroundColor: `#${software.risk}` }}
                className="risk-rate ms-5"
              ></span>
            </p>
          ),
          width: "5%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <Link
              to={`/admin/data-protection/incident-register/details/${software?.case_id}`}
              onClick={() => localStorage.setItem("caseId", software?.case_id)}
            >
              <p className="text-truncate text-capitalize text-left">
                {software?.secondary_case_id || ""}
              </p>
            </Link>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center">
              {/* {software?.admin_description  || "" } */}
              {"admin_description" in software &&
              software?.admin_description.trim()
                ? software?.admin_description.length > 70
                  ? `${software?.admin_description?.substring(0, 70)}...`
                  : software?.admin_description
                : software?.description
                ? software?.description.length > 70
                  ? `${software?.description.substring(0, 70)}...`
                  : software?.description
                : "---"}
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center d-flex justify-content-center">
              {dateUtils.convertTimestampToOnlyDate(
                software?.submission_date
              ) || ""}
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center d-flex justify-content-center">
              {software?.closing_date
                ? dateUtils.convertTimestampToOnlyDate(software?.closing_date)
                : ""}
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center">
              {software?.analyst_assigned.id
                ? `${software?.analyst_assigned.first_name} ${software?.analyst_assigned.last_name}`
                : ""}
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        {
          value: (
            <p className="text-truncate text-capitalize text-center">
              <div>{handleRenderReportStatus(software?.status)}</div>
            </p>
          ),
          width: "10%",
          isRecentlyAdded: software?.isRecentlyAdded,
        },
        // {
        //   value: (
        //     <p className="text-truncate text-capitalize text-center">
        //       {software.operating_system_name}
        //     </p>
        //   ),
        //   width: "10%",
        //   isRecentlyAdded: software?.isRecentlyAdded,
        // },

        // {
        //   value:
        //     software.status !== "DELETED" ? (
        //       <div className=" text-danger text-truncate text-capitalize text-center d-flex justify-content-center remove">
        //         {
        //           <a onClick={() => onRemoveClick(software.id)}>
        //             <i className="fa fa-trash" aria-hidden="true"></i>
        //           </a>
        //         }
        //       </div>
        //     ) : (
        //       <span className="text-danger text-truncate text-capitalize text-center d-flex justify-content-center remove">
        //         {props.t("Deleted")}
        //       </span>
        //     ),
        //   width: "5.3%",
        //   isRecentlyAdded: software?.isRecentlyAdded,
        // },
      ];
    });
  };

  const toggleAssetFlag = (asset) => {
    toggleSoftwareFlagMutation.mutate(
      {
        softwareId: asset.id,
      },
      {
        onSuccess: () => {
          setSoftwares({
            ...softwares,
            assets: softwares.assets.map((software) => {
              if (software.id === asset.id) {
                return {
                  ...software,
                  flag: !asset.flag,
                };
              }
              return software;
            }),
          });
        },
      }
    );
  };

  const handleRenderFlag = (asset) => {
    return (
      <span
        onClick={() => toggleAssetFlag(asset)}
        className="flag-icon d-flex align-items-center justify-content-center text-center"
        role="button"
      >
        {asset.flagIsUpdating === true ? (
          <Spinner animation="border" variant="secondary" size="sm" />
        ) : (
          <img
            src={asset.flag ? flagOnIcon : flagOffIcon}
            alt="Flag Icon"
            className="object-fit-contain d-inline-block mw-100"
            style={{ width: "24px" }}
          />
        )}
      </span>
    );
  };

  const handleRenderPriority = (priority) => {
    switch (priority) {
      case "HIGH": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={highPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {priority.toLowerCase()}
          </div>
        );
      }
      case "MEDIUM": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={mediumPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {priority.toLowerCase()}
          </div>
        );
      }
      case "LOW": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={lowPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {priority.toLowerCase()}
          </div>
        );
      }
    }

    return "---";
  };

  const handleRenderStatus = (status) => {
    switch (status) {
      case "NEW": {
        return (
          <div className="text-capitalize status bg-success text-white">
            {SoftwareStatuses[status]}
          </div>
        );
      }
      case "OPEN": {
        return (
          <div className="text-capitalize status bg-light">
            {SoftwareStatuses[status]}
          </div>
        );
      }
      case "IN_PROGRESS": {
        return (
          <div className="text-capitalize status bg-primary text-white">
            {SoftwareStatuses[status]}
          </div>
        );
      }
      case "ASSIGNED":
        <div className="text-capitalize status bg-secondary text-white">
          {SoftwareStatuses[status]}
        </div>;
      case "DELETED": {
        return (
          <div className="text-capitalize status bg-danger text-white">
            {SoftwareStatuses[status]}
          </div>
        );
      }
      case "APPROVED": {
        return (
          <div className="text-capitalize status bg-dark text-white">
            {SoftwareStatuses[status]}
          </div>
        );
      }
    }

    return "---";
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const pageIndex = searchParams.get("pageIndex");
    const defaultPageIndex = "1";

    if (Number(pageIndex) === Number(defaultPageIndex)) {
      handleFetchIncidentListQuery.refetch();
    } else {
      searchParams.set("pageIndex", defaultPageIndex);
      history.replace({ search: searchParams.toString() });
    }

    searchParams.set("pageIndex", "1");
  }, [filterStatus, filterFlag, filterAssetOwner]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const pageIndex = searchParams.get("pageIndex");
    if (pageIndex !== null && !Number.isNaN(Number(pageIndex)))
      setPageIndex(Number(pageIndex));

    const pageSize = searchParams.get("pageSize");
    if (pageSize !== null && !Number.isNaN(Number(pageSize)))
      setPageSize(Number(pageSize));
  }, [location.search]);

  useEffect(() => {
    if (handleFetchIncidentListQuery.data) {
      setTotalCount(Number(handleFetchIncidentListQuery.data.itemsCount));
    }

    if (deletionComplete) {
      handleFetchIncidentListQuery.refetch();
      setDeletionComplete(false);
    }
  }, [deletionComplete, handleFetchIncidentListQuery.data]);

  useEffect(() => {
    if (fetchUsers.data && fetchUsers.data?.length) {
      const users = fetchUsers.data.map((u) => {
        return {
          value: u.id,
          label: u.first_name + " " + u.last_name,
        };
      });

      setUsers(users);
    }
  }, [fetchUsers.data]);

  useEffect(() => {
    if (handleFetchIncidentListQuery.data) {
      setSoftwares(handleFetchIncidentListQuery.data);
    }
  }, [handleFetchIncidentListQuery.data]);

  return (
    <>
      <Container fluid className="mt-2">
        <div className="d-flex mb-4 justify-content-end align-items-center w-full px-5 flex-wrap gap-3">
          <div style={{ width: "180px" }}>
            <Select
              classNamePrefix="select2-selection"
              options={[
                {
                  value: "all",
                  baseLabel: "All Statuses",
                },
                ...Object.keys(SoftwareStatuses).map((key) => ({
                  value: key,
                  baseLabel: SoftwareStatuses[key],
                })),
              ].map((s) => {
                return {
                  value: s.value,
                  baseLabel: s.baseLabel,
                  label: props.t(s.baseLabel),
                };
              })}
              value={filterStatus}
              onChange={(selectedStatus) => {
                setFilterStatus(selectedStatus);
              }}
              placeholder={props.t("Select Status")}
            />
          </div>
          <div style={{ width: "180px" }}>
            <Select
              classNamePrefix="select2-selection"
              options={[
                {
                  value: "all",
                  baseLabel: "All Flags",
                },
                {
                  value: true,
                  baseLabel: "Flag On",
                },
                {
                  value: false,
                  baseLabel: "Flag Off",
                },
              ].map((f) => {
                return {
                  value: f.value,
                  baseLabel: f.baseLabel,
                  label: props.t(f.baseLabel),
                };
              })}
              onChange={(selectedFlag) => {
                setFilterFlag(selectedFlag);
              }}
              placeholder={props.t("Select Flag")}
            />
          </div>
          <div style={{ width: "180px" }}>
            <Select
              classNamePrefix="select2-selection"
              isLoading={fetchUsers.isLoading || fetchUsers.isFetching}
              isError={fetchUsers.isError}
              options={[
                {
                  label: t("All Owners"),
                  value: "all",
                },
                ...users,
              ]}
              onChange={(owner) => {
                setFilterAssetOwner(owner);
              }}
              placeholder={props.t("Asset Owner")}
            />
          </div>
          {/* <Link
            className="btn btn-outline-primary me-2"
           
          >
            {props.t("Download PDF")}
            <span className="ps-1">
            </span>
          </Link> */}
          <Link
            className="btn btn-primary"
            to={`/admin/data-protection/incident-register?softwareId=${assetId}`}
          >
            {props.t("Add A New Incident")}
          </Link>
        </div>

        {/* page body */}
        <PageBody classNames="box-shadow">
          {!handleFetchIncidentListQuery.isLoading ||
          !handleFetchIncidentListQuery.isFetching ? (
            !handleFetchIncidentListQuery.isError ? (
              softwares?.reports && softwares?.reports?.length ? (
                // data
                <Fragment>
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <CustomTable
                        headerData={tableHeader}
                        data={handleProvideTableData(softwares.reports)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <Pagination
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        totalCount={totalCount}
                        link={location.pathname}
                      />
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                // empty
                <Row>
                  <Col sm="12" md="12" lg="12">
                    <Fragment>
                      <CustomTable headerData={tableHeader} data={[]} />
                      <div className="p-2">
                        <div className="alert alert-secondary text-center">
                          {props.t("This List Is Empty.")}
                        </div>
                      </div>
                    </Fragment>
                  </Col>
                </Row>
              )
            ) : (
              // error
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Fragment>
                    <CustomTable headerData={tableHeader} data={[]} />
                    <div className="p-2">
                      <div className="alert alert-danger text-center">
                        {props.t("Failed To Fetch Softwares.")}
                      </div>
                    </div>
                  </Fragment>
                </Col>
              </Row>
            )
          ) : (
            // loading
            <Row>
              <Col sm="12" md="12" lg="12">
                <Fragment>
                  <CustomTable headerData={tableHeader} data={[]} />
                  <div className="p-2">
                    <div className="alert alert-info text-center">
                      {props.t("Loading")} ...
                    </div>
                  </div>
                </Fragment>
              </Col>
            </Row>
          )}
        </PageBody>
      </Container>

      {/* Deletion Modal */}
      {users?.length && (
        <Deletion
          t={t}
          modal={showDeletionModal}
          setModal={setDeletionModal}
          users={users}
          id={selectedId}
          setDeletionComplete={setDeletionComplete}
        />
      )}
      {/* </PageContent> */}
    </>
  );
};

export default withNamespaces()(
  withRouter(
    connect(null, {
      errorNotification,
      successNotification,
    })(memo(DataProtectionSoftwareRelatedIncidents))
  )
);
